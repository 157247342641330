import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Errors, UserService } from '../../../core';
import { CommonService } from "src/app/core/services/common.service";
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { UserTypeConstants, CatlougeTypeConstants, resendOtpTimer } from "src/app/core/constants";
import { PlayListService } from '../../playlist/playlist/playlist.service';
import { NgbModal, ModalDismissReasons, } from '@ng-bootstrap/ng-bootstrap';


declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [PlayListService]
})
export class RegisterComponent implements OnInit {

  errors: Errors = { errors: {} };
  registerForm: FormGroup;
  @ViewChild('closebutton') closebutton;
  timezone: any = '';
  otpScreen: boolean = false;
  isregistred: boolean = false;
  hide: boolean = true;
  loginPageStatus: boolean = false;
  signUpPageStatus: boolean = true;

  // Form Submitted variable status 
  isSignUpSubmited: boolean = false;
  isForgotSubmitted: boolean = false;
  isResetSubmitted: boolean = false;
  isOtpSubmitted: boolean = false;
  userExist: any;
  userExistLimit: string = '';


  // Hide Show Password
  passHide: boolean = true;
  cPassHide: boolean = true;
  verifyForm: FormGroup;
  verifySuccessMsg: string;
  otpHide: boolean = false;

  timezoneForGA: any;
  locale: any;
  timeZoneName: any;
  otpInt: any = 1;
  maxOtpAttempt = 5;
  passLengthError: string;
  error_code: any;

  mobileNumber: string = '';
  emailId: string = '';

  errorCode1: any;
  @ViewChild('openTellUsModel') openTellUsModel: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    public commonService: CommonService, private googleEvent: EventTrackingService,
    public playListService: PlayListService,
    private modalService: NgbModal
  ) {

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(this.timezone);
    this.registerForm = this.fb.group({
      'pass': ['', [Validators.required, Validators.minLength(8)]],
      'cpass': ['', [Validators.required]],
      'catlogue': [CatlougeTypeConstants.catlogue],
      'device_id': ['web'],
      'platform': ['web'],
      'ipaddress': ['127.0.0.1'],
      'device_token': ['web'],
      'os_version': ['windows 10'],
      'build_version': ['1.0.0'],
      'fb_id': [''],
    }, {
      validator: this.commonService.passwordMatch('pass', 'cpass')
    });

    this.verifyForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.minLength(6)]],
      'catlogue': [CatlougeTypeConstants.catlogue, [Validators.required]],
    });

    this.registerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    this.verifyForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    // this.registerForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
    // this.verifyForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));

    
    // if (this.timezone == 'Asia/Calcutta') {
    //   this.registerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    //   this.verifyForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    // } else {
    //   this.registerForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
    //   this.verifyForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
    // }

    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;


  }



  get registerFormControls() { return this.registerForm.controls; }

  get otpFormControls() { return this.verifyForm.controls; }

  setMobileEmailFormCOntroll(forname) {
    // if(this.timezone == 'Asia/Calcutta'){
    //   forname.addControl('mobile',new FormControl('', [Validators.required, Validators.minLength(10)]));
    // } else {
    //   forname.addControl('emailaddress',new FormControl('', [Validators.required, Validators.email]));
    // }
  }
  ngOnInit() {
    console.log(this.timezone);
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone =='Asia/Kolkata'?'Asia/Calcutta':'Asia/Calcutta';    
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localStorage.getItem('isAuthenticated')) {
      this.router.navigateByUrl('/');
    }
  }


  chnageRegisterVia(){
      console.log(this.timezone);
      if (this.timezone == 'Asia/Calcutta') {

        this.timezone = ''; 
        this.registerForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
        this.verifyForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));

        this.registerForm.removeControl('mobile');
        this.verifyForm.removeControl('mobile');
      } else {

        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.registerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
        this.verifyForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));

        this.registerForm.removeControl('emailaddress');
        this.verifyForm.removeControl('emailaddress');
      }
  



    }


  register() {
    this.userExistLimit = '';
    this.errors = { errors: {} };
    this.isSignUpSubmited = true;
    if (this.registerForm.invalid) {
      return;
    }
    try {

    } catch (e) {

    }
    let credentials = this.registerForm.value;
    console.log(credentials);
    credentials.pass = this.registerForm.value.pass.trim();
    credentials.cpass = this.registerForm.value.cpass.trim();

    this.mobileNumber = credentials.mobile;
    this.emailId = credentials.emailaddress;

    console.log(`mobile--${credentials.mobile}`);
    console.log(`email--${credentials.emailaddress}`);
    // console.log(credentials.cpass.length);
    if (credentials.pass.length < 8 || credentials.cpass.length < 8) {
      this.userExist = 'Password must be at least 8 characters';
      return;
    } else {
      this.userExist = ''
    }


    if(this.mobileNumber!="" && this.mobileNumber!=undefined){
    if (this.mobileNumber.toString().length< 10) {
      this.userExist = 'Please enter 10 digit mobile number.';
      return;
    } else {
      this.userExist = ''
    }
  }

    delete credentials['cpass'];
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.attemptAuth(urlEncodedBodyData).subscribe(data => {
      console.log(data);
      this.userExist = ''
      localStorage.setItem('sessionId', data['result']['sessionId'])
      if (data['isregistred'] == true) {
        this.isregistred = true;
      }

      if (data['error_code'] == "201" || data['error_code'] == "200" || data['error_code'] == "428") {
        this.otpScreen = true;
        this.userExist = data['error_string'];

        if (this.timezone == 'Asia/Calcutta') {
          this.verifyForm.patchValue({
            mobile: credentials['mobile']
          })
        } else {
          this.verifyForm.patchValue({
            emailaddress: credentials['emailaddress']
          })
        }
        this.resendOtpTimer()
        // this.verifySuccessMsg=' ';
      } else if (data['error_code'] == '429') {
        console.log(data['error_string']);
        this.userExist = '';//data['error_string'];
        this.userExistLimit = data['error_string'];

      }
      else if (data['error_code'] == '428') {
        this.otpScreen = true;
        this.userExist = data['error_string'];

      }
      if (data['error_code'] == "214") {
        this.userExist = data['error_string']
      }
      if (data['error_code'] == "202") {
        this.userExist = data['error_string']
        // this.userExist = 'Incorrect OTP. Please try again.'
      }

      // this.closebutton.nativeElement.click();
      // this.router.navigateByUrl('/home');
    }, err => {
      this.errors = err;
    });
  }


  verifyOtp() {

    this.errors = { errors: {} };
    console.log(this.verifyForm);
    this.isOtpSubmitted = true;
    if (this.verifyForm.invalid) {
      // return;
    }
    const credentials = this.verifyForm.value;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.verifyOtp(urlEncodedBodyData).subscribe(data => {
      console.log(data);
      if (data['error_code'] == "202") {
        this.userExist = data['error_string']
      }

      if (data['error_code'] == "429") {
        this.error_code = data['error_code'];
        this.userExist = data['error_string']
      }

      if (data['error_code'] == "200") {
        this.userExist = '';
        this.verifySuccessMsg = 'Verified'

        setTimeout(() => {
          console.log(data);
          // localStorage.setItem('userInfo', data)
          this.closebutton.nativeElement.click();
          //this.router.navigateByUrl('/');

          let credentials = this.registerForm.value;
          let username = '';
          if (credentials.mobile == null || credentials.mobile == undefined) {
            username = credentials.emailaddress
          } else {
            username = credentials.mobile;
          }

          this.googleEvent.eventEmitter('SignUp' , 'SignUp' , this.locale + ' - ' + this.timezoneForGA, 0, username, username + '-' + this.locale + ' - ' + this.timezoneForGA);
          this.signin(username, this.registerForm.value.pass.trim());
        }, 500);
      }

    }, err => {
      this.errors = err;
    });

  }


  hidePassword() {
    this.hide = !this.hide;
  }

  login() {
    this.loginPageStatus = true;
    this.signUpPageStatus = false;
  }
  signin(usename, password) {
    let credentials =
    {
      'pass': password,
      'catlogue': [CatlougeTypeConstants.catlogue],
      'device_id': ['web'],
      'platform': ['web'],
      'ipaddress': ['127.0.0.1'],
      'device_token': ['web'],
      'os_version': ['windows 10'],
      'build_version': ['1.0.0'],
      'fb_id': ['']
    };
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if ((usename) && reg.test(usename) == false) {
      credentials['mobile'] = usename;
    } else {
      credentials['emailaddress'] = usename;
    }


    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.login(urlEncodedBodyData).subscribe(data => {
      if (data.error_code == "200") {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('sessionId', data.result.sessionId);
        localStorage.setItem('debug', 'false');

        localStorage.setItem('parentControl', data.result.parental_control);
        //this.getPlaylist();

             // show TeelUs Popup Files Api Hit Start
             let da = "";
             this.userService.getTellUsFields(da).subscribe(data1 => {
               this.errorCode1 = data1['error_code'];
               if (this.errorCode1 == "200") {
                 if (data1['result']['is_profile_complete'] == 0  && data1['result']['is_additional_profile_field_skipped'] == 0 && data1['result']['additional_field_data'].length>0) {
                   this.closebutton.nativeElement.click();
                   this.openTellUsModel.nativeElement.click();
                 } else {
                   this.getPlaylist();  
                 }
               }
     
             }, err => {
               this.errors = err;
             });

        // this.router.navigateByUrl(this.currentRoute);
      } else {
      }

    }, err => {
      this.errors = err;
    });
  }

  openTellUs(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false, windowClass:'tellu'}).result.then((result) => {
    }, (reason) => {
    });

  }

  getPlaylist() {
    let tempGetPlay = {
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getplaylist",
    }

    let urlEncodedPlayListParams = this.userService.convertoJSONToUrlEncoded(tempGetPlay);

    this.playListService.getPlaylist(urlEncodedPlayListParams).subscribe(data => {
      // this.playList = data;

      if (data && data.result) {
        localStorage.setItem('playListId', data?.result[0]?.playlistid);
        this.userService.loginStatus(true);
        this.closebutton.nativeElement.click();
        //this.closeModal();

        if (Object.keys(this.commonService.getAssetsData()).length > 0)
          this.commonService.goToDetail(this.commonService.getAssetsData(), '', 1);
        else
          window.location.reload();


      }
    });
  }


  closeModal() {
    document.querySelector("ngb-modal-window").classList.remove("show");
    document.querySelector("ngb-modal-backdrop").classList.remove("show");
    document.getElementById('nexgTV-app').click();
    document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
    document.querySelectorAll(".d-block").forEach(el => el.remove())
    document.body.style.overflow = "scroll";

  }
  clearResponseError() {
    this.userExist = '';
  }

  requestForOtp() {

    // this.resendOTPstatus = true;
    let otpObj = {
      catlogue: CatlougeTypeConstants.catlogue,
      send_for: 'signup'
    }
    this.userService.requestOtp(otpObj).subscribe(data => {
      this.error_code = data['error_code'];

      if (data.error_code == "201") {
        this.userExist = data['error_string'];
        this.resendOtpTimer();
      }

      if (data.error_code == "429") {
        // this.maxOtpAttempt = 0;
        this.userExist = data['error_string'];
      }
      else if (data['error_code'] == '428') {
        this.userExist = data['error_string'];
      }


    });
  }

  resendOtpTimer() {
    this.otpInt = resendOtpTimer.otpTimer;
    // this.resendOTPstatus=true;
    this.maxOtpAttempt--;
    let timerInterval = setInterval(() => {
      if (this.otpInt == 0) {
        clearInterval(timerInterval);
        // this.otpInt = 60;  
        console.log(this.otpInt);
      }
      this.otpInt--;
      console.log(this.maxOtpAttempt);
    }, 1000);

  }

  reloadPage() {
    window.location.reload();
  }


  termsPageFn() {
    window.open("terms", "_blank");
  }
}
