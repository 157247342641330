<section class="_custom_paren_img mini_slider wow animate__animated animate__fadeInUp"
    [ngClass]="{'assets_hover': assets_hover }" *ngIf="data && data.assets && data.assets.length > 0">
    <div class="_custom_cont" *ngIf="data.assets.length>0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="i_head_c">
                        <!-- <h4 *ngIf="data.assets && data.assets[0].type != 'livetv' && data.assets[0].type != 'event'">{{data.category_name}}</h4> -->
                        <h4>{{data.category_name}}</h4>

                        <a *ngIf="data.assets && data.assets[0].type != 'livetv' && data.assets[0].type != 'event' && data.assets.length > showViewAll"
                            (click)="viewall(data)">View all</a>
                        <a *ngIf="data.assets && (data.assets[0].type == 'livetv' ||  data.assets[0].type == 'event') && data.assets.length > showViewAll"
                            (click)="viewall(data)">View all</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Four slider -->

    <div class="_custom_grid_img mb40" [ngClass]="{'assets_hover': assets_hover }">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="owl-carousel four_slides owl-theme {{thumbnailClass?thumbnailClass : 'landscape_assets'}}"
                        [ngClass]="{'_less_four':data.assets.length <= 4 }">
                        <owl-carousel-o [options]="owlOption">
                            <ng-container *ngFor="let item of data.assets; let i=index">
                                <ng-template carouselSlide>
                                    <div class="item" (click)="redirectUrl(item, content, jwplaydom, $event)">

                                        <div class="_watching_img1" (mouseenter)="item_hover_enter()"
                                            (mouseleave)="item_hover_leave()"
                                            [ngClass]="{'live_tv': item.type == 'livetv'}">
                                            <div class="p_g_i" id="cont_thombImage{{i}}">
                                                
                                                <image-manipulation *ngIf="data.category_type == 'continue-watching'" [img]="isCloudFrontType == false ? item.thumbnail : item.thumbnail_cloudfront_url" [params]="{'width':330,'height':180}" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'portrait'" [img]="isCloudFrontType == false ? item.thumbnail_portrait || item.thumbnail : item.thumbnail_portrait_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'landscape'" [img]="isCloudFrontType == false ? item.thumbnail : item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'circular'" [img]=" isCloudFrontType == false ? item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'stretched-landscape'" [img]=" isCloudFrontType == false ? item.thumbnail_stretched_landscape || item.thumbnail : item.thumbnail_stretched_landscape_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'square'" [img]="isCloudFrontType == false ? item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'enlarged-landscape'" [img]=" isCloudFrontType == false ? item.thumbnail || item.thumbnail : item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'enlarged-square'" [img]="isCloudFrontType == false ? item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'enlarged-circle'" [img]="isCloudFrontType == false ? item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>

                                                <a class="_premium"
                                                    *ngIf="strToLower(item.content_availability) == 'paid'">
                                                    <img class="crown" src="assets/img/crown.png">
                                                </a>

                                                <a class="_free_tag"
                                                    *ngIf="strToLower(item.content_availability) == 'free'">
                                                    <img class="crown" src="assets/img/free_tag_76x35.png">
                                                </a>

                                                <a class="_free_tag"
                                                    *ngIf="strToLower(item.content_availability) == 'no tag'">
                                                    <!-- <img class="crown" src="assets/img/free_tag_76x35.png"> -->
                                                </a>

                                                <a class="_cross_icon"
                                                    *ngIf="strToLower(data.category_name) == 'continue watching'">
                                                    <img class="crown" (click)="deleteWatch($event, item)"
                                                        src="assets/img/asset_cross_icon.png">
                                                </a>
                                                <!-- <span class="live" *ngIf="item.type == 'livetv'">Live</span> -->
                                                <!-- <div *ngIf="data.category_name == 'Continue Watching'" class="progress">                                                
                                                 <div  class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': item.stop*100/item.duration+'%'}" >                                  
                                                 </div>
                                             </div> -->

                                                <!-- <div *ngIf="item.type == 'livetv'" class="progress">
                                                 <div  class="progress-bar" role="progressbar" aria-valuenow="{{item.totalPlayed}}" aria-valuemin="0" aria-valuemax="{{item.duration}}" 
                                                 [ngStyle]="{'width': item.totalPlayed +'%'}" >                                  
                                                 </div>
                                             </div> -->

                                            </div>
                                            <div class="_visible_hover1" id="cont_thombMore{{i}}">
                                                <div *ngIf="strToLower(data.category_name) == 'continue watching'">
                                                    <div class="_v_c_l  showContinueIcon">
                                                        <a href="javascript:;">
                                                            <div class="_c_img">
                                                                <img id="{{item.name}}" src="assets/img/hover_Play.png">
                                                            </div>
                                                            <div class="_c_progress ">
                                                                <span *ngIf="item.stop > 0">Continue Watching <br />
                                                                    <span style="text-transform: capitalize;"
                                                                        *ngIf="item.type == 'episode'">
                                                                        S{{item.season?.season_number}} Episode
                                                                        {{item.no}} </span>
                                                                </span>
                                                                <span *ngIf="item.stop <= 0">Watch now
                                                                    <span *ngIf="item.type == 'episode'">
                                                                        S{{item.season?.season_number}} E{{item.no}}
                                                                    </span>
                                                                </span>
                                                                <div *ngIf="strToLower(data.category_name) == 'continue watching'  && item.stop > 0 "
                                                                    class="progress">
                                                                    <div class="progress-bar" role="progressbar"
                                                                        aria-valuenow="70" aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                        [ngStyle]="{'width': item.stop*100/item.duration+'%'}">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <!-- <a href="javascript:;" >
                                                 <div class="_c_img">
                                                 <img id="{{item.name}}"  src="assets/img/hover_Play.png">
                                                 </div>
                                                 <div class="_c_progress " *ngIf="strToLower(data.category_name) == 'continue watching'">
                                                     <span *ngIf="item.stop > 0">Continue Watching <br/>
                                                        <span style="text-transform: capitalize;" *ngIf="item.type == 'episode'"> S{{item.season?.season_number}} Episode {{item.no}} </span> 
                                                     </span>
                                                     <span *ngIf="item.stop <= 0">Watch now  
                                                         <span *ngIf="item.type == 'episode'"> S{{item.season?.season_number}} E{{item.no}} </span> 
                                                     </span>
                                                     <div *ngIf="strToLower(data.category_name) == 'continue watching'  && item.stop > 0 " class="progress">                                                
                                                         <div  class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': item.stop*100/item.duration+'%'}" >                                  
                                                         </div>
                                                     </div>
                                                 </div>
                                                 <div class="_c_progress">
                                                 <span *ngIf="strToLower(data.category_name) != 'continue watching'">watch now</span>
                                             </div>
                                             </a>  -->
                                                        <!-- <div class="_right_c_t">
                                                 <span class="rating_s" *ngIf="(item?.viewer_rating) && item?.viewer_rating?.rating_name">
                                                     {{item?.viewer_rating?.rating_name}}
                                                 </span>
 
                                                 <span class="rating_s" *ngIf="item?.viewer_rating && Object.keys(item.viewer_rating).length > 0">
                                                     {{item.viewer_rating?.rating_name}}
                                                 </span>
                                                 <a class="add_play_llist" (click)="AddToPlayListItems($event, item, content)"> 
                                                     <img [src]="item.is_watchlist_added?'assets/img/Added_to_Watchlist.png':'assets/img/Add-to_Watchlist.png'">                                                                                               
                                                     <span class="_hover_watchlist">
                                                         {{item.is_watchlist_added?'Remove from Watchlist':'Add to Watchlist'}}   
                                                     </span>
                                                 </a>
                                             </div>                                -->

                                                    </div>
                                                </div>
                                                <div class="_inner_text_l">
                                                    <h4>{{item.name | slice:0:40}}
                                                        <span *ngIf="item.name && item.name.length > 40">...</span>
                                                    </h4>

                                                    <p class="type_film">
                                                        {{item.languages?item.languages:''}}

                                                        {{item.genre_text&&item.languages?"|":""}}

                                                        {{item.genre_text?item.genre_text:''}}


                                                        <!--     
                                                     {{(item.hours||item.min||item.sec) && item.genre_text?'|': ''}} 
                                                     {{item.hours>0?item.hours+' hrs ':''}}
                                                     {{item.min>0?item.min+' min ':''}}
                                                     {{item.sec>0?item.sec+' sec ':''}}
                                                     {{item.languages&&(item.hours||item.min||item.sec)?"|":""}} 
                                                 -->


                                                        <span class="rating_s"
                                                            *ngIf="(item?.viewer_rating) && item?.viewer_rating?.rating_name">
                                                            | {{item?.viewer_rating?.rating_name}}
                                                        </span>
                                                    </p>

                                                    <p>{{item.synopsis | slice:0:100}}
                                                        <span
                                                            *ngIf="item.synopsis && item.synopsis.length > 100">...</span>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>



</section>


<a data-toggle="modal" data-target="#addToWatchList" #addToWatchListSuccess></a>

<div class="modal auth" id="addToWatchList">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="visibility: hidden;">
                <button type="button" id="closeAddToWatchList" class="close" data-dismiss="modal"
                    #removeToWatchListSuccessModal>&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="success-add">
                    <img src="../../../assets/img/success.png">
                    <p id="itemAddSuccessMsg" #itemAddedIntoWach></p>

                </div>
            </div>
        </div>
    </div>
</div>


<!--Cancel subscription -->
<div class="modal auth" id="add_watch_list_from_slider" *ngIf="userInfo">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure ?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeAddtoWatchListModal>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div class="modal-body" *ngIf="remove_subscription_error != ''">
             <p class="error">{{remove_subscription_error}}</p>
             </div> -->
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="AddToPlayListItemFromSlider()">Yes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                <a href="" data-toggle="modal" data-target="#pack_removed_success" #showPackSuccess></a>
            </div>
        </div>
    </div>
</div>
<!-- Cancel subscription -->

<!--Cancel subscription -->
<div class="modal auth" id="removeWatchListSlider" *ngIf="userInfo">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure ?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div class="modal-body" *ngIf="remove_subscription_error != ''">
             <p class="error">{{remove_subscription_error}}</p>
             </div> -->
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="deleteWatch()">Yes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                <a href="" data-toggle="modal" data-target="#pack_removed_success" #showPackSuccess></a>
            </div>
        </div>
    </div>
</div>


<a data-toggle="modal" data-target="#removeContinueModal" #closeContinueModal></a>

<div class="modal auth" id="removeContinueModal" *ngIf="userInfo" #closeContinueModal>
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="visibility: hidden;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="success-add">
                    <img src="../../../assets/img/success.png">
                    <p>Successfully Cleared.</p>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- JW Player  -->
<ng-template #jwplaydom let-modal>
    <!-- <app-jw-video-player [streamInfo]="streamInfo"></app-jw-video-player> -->
    <app-videojs-player [streamInfo]="streamInfo"></app-videojs-player>
</ng-template>

<!-- Cancel subscription -->
<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>