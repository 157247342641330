import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AccountService {
  END_POINT = environment.api_url;
  constructor(private http: HttpClient) { }

  getData(apiMethod: string, param?: any): Observable<any> {
    return this.http.get(this.END_POINT + apiMethod, param).pipe(
      catchError(this.handleError)// then handle the error
    );
  }

  postData(apiMethod: string, param: any): Observable<any> {
    return this.http.post(this.END_POINT + apiMethod, param).pipe(
      catchError(this.handleError)// then handle the error
    );
  }

  updateData(id: number, param: any): Observable<any> {
    return this.http.put(this.END_POINT + id, param).pipe(
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    console.log(HttpErrorResponse);
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  
}
