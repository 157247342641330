import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, UserService } from '../../../core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { EmailService } from '../../../core/services/email.service';
import { PlatformLocation, Location } from '@angular/common';
import { UserTypeConstants, CatlougeTypeConstants, resendOtpTimer } from "src/app/core/constants";

import { CommonService } from "src/app/core/services/common.service";
import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone'; // add this 1 of 4
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { NgbModal, ModalDismissReasons, } from '@ng-bootstrap/ng-bootstrap';
import { json } from 'express';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [PlayListService]
})

export class LoginComponent implements OnInit {
  evalUser: boolean = false;
  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  otpForm: FormGroup
  resetPasswordForm: FormGroup
  errors: { errors: {}; };
  @ViewChild('closebutton') closebutton: ElementRef;
  timezone: any;
  currentRoute: string;
  hide: boolean = true;
  forgotPasswordComponent: boolean = false;
  loginPage: boolean = true;
  otpInputbox: boolean = false;
  resetPassword: boolean = false;
  resetPasswordSuccess = false;
  forgotPasswordResponse: any;

  isLoginSubmitted: boolean = false;
  isForgotSubmitted: boolean = false;
  isResetSubmitted: boolean = false;
  isOtpSubmitted: boolean = false;
  responseErr: string;

  // password Text Button
  rPassdHide: boolean = true;
  rConfirmPassHide: boolean = true;
  loginPassHide: boolean = true;
  loginPageStatus: boolean;
  signUpPageStatus: boolean;
  vaildEmail = `Please enter the OTP we have sent to your registered Email ID`;
  vaildMobile = `Please enter the OTP we have sent via SMS to your registered mobile number`;
  resendOTPstatus: boolean;
  otpInt: any = 1;
  maxOtpAttempt = 5;
  timezoneForGA: any;
  locale: any;
  timeZoneName: any;
  error_code: any;

  mobileNumber: string = '';
  emailId: string = '';
  errorCode: any;
  @ViewChild('openTellUsModel') openTellUsModel: ElementRef;
  afterLoagindata: any;
  landingPage: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private emailService: EmailService,
    private platformLocation: PlatformLocation,
    location: Location,
    private fb: FormBuilder,
    public commonService: CommonService,
    public playListService: PlayListService,
    private googleEvent: EventTrackingService,
    private modalService: NgbModal
  ) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.currentRoute = location.path();
      } else {
        this.currentRoute = "/";
      }
    });

    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;

    //this.googleEvent.eventEmitter(this.locale + ' - ' + this.timezoneForGA, 'Login', 'Login button click');
  }

  ngOnInit() {
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone =='Asia/Kolkata'?'Asia/Calcutta':'Asia/Calcutta';
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localStorage.getItem('isAuthenticated')) {
      this.router.navigateByUrl('/');
    }

    //'emailaddress': ['', [Validators.required, Validators.email]],

    this.loginForm = this.fb.group({
      'pass': ['', [Validators.required]],
      'catlogue': [CatlougeTypeConstants.catlogue],
      'device_id': ['web'],
      'platform': ['web'],
      'ipaddress': ['127.0.0.1'],
      'device_token': ['web'],
      'os_version': ['windows 10'],
      'build_version': ['1.0.0'],
      'fb_id': ['']
    });

    this.otpForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.minLength(6)]],
    });

    this.resetPasswordForm = this.fb.group({
      'pass': ['', [Validators.required, Validators.minLength(8)]],
      'cpass': ['', [Validators.required]]
    }, {
      validator: this.commonService.passwordMatch('pass', 'cpass')
    });


    if (this.timezone == 'Asia/Calcutta') {

      this.loginForm.addControl('emailaddress', new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
      ]));
      this.forgotPasswordForm = this.fb.group({
        'emailaddress': ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
      });
      // this.otpForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]));
      // this.resetPasswordForm.addControl('mobile',new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]));

    } else {

      this.loginForm.addControl('emailaddress', new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]));
      this.forgotPasswordForm = this.fb.group({
        'emailaddress': ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
      });
      // this.otpForm.addControl('emailaddress',new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
      // this.resetPasswordForm.addControl('emailaddress',new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));

    }
  }




  get loginFormControls() { return this.loginForm.controls; }
  get forgotFormControls() { return this.forgotPasswordForm.controls; }
  get otpFormControls() { return this.otpForm.controls; }
  get resetFormControls() { return this.resetPasswordForm.controls; }


  clearResponseError() {
    this.responseErr = '';
  }

  login() {

    this.errors = { errors: {} };
    this.isLoginSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    const credentials = this.loginForm.value;
    // if(this.timezone == 'Asia/Calcutta' || (this.loginForm.get('emailaddress') == null){
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    console.log(credentials);
    if ((this.loginForm.get('emailaddress')) && reg.test(this.loginForm.get('emailaddress').value) == false) {
      delete credentials['emailaddress'];
      credentials['mobile'] = this.loginForm.get('emailaddress').value;
    } else {
      delete credentials['mobile'];
      credentials['emailaddress'] = this.loginForm.get('emailaddress').value;
    }

    console.log(credentials);

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.login(urlEncodedBodyData).subscribe(data => {
      if (data.error_code == "200") {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('sessionId', data.result.sessionId);
        localStorage.setItem('debug', 'false');
        localStorage.setItem('parentControl', data.result.parental_control);
       let da = "";
       let mob = data.result.mobile;
       let eml = data.result.emailaddress
       this.googleEvent.eventEmitter( 'Login', 'Login', mob?mob:eml + '-' + this.locale + ' - ' + this.timezoneForGA , 0 , mob?mob:eml , mob?mob:eml +'-' +this.locale + ' - ' + this.timezoneForGA);
       this.userService.getTellUsFields(da).subscribe(data1 => {
         this.errorCode = data1['error_code'];
         if (this.errorCode == "200") {
           if (data1['result']['is_profile_complete'] == 0  && data1['result']['is_additional_profile_field_skipped'] == 0 && data1['result']['additional_field_data'].length>0) {
              this.closebutton.nativeElement.click();
              this.openTellUsModel.nativeElement.click();
           } else {
             this.getPlaylist();
           }
         }

       }, err => {
         this.errors = err;
       });

        // this.router.navigateByUrl(this.currentRoute);
      } else {
        this.responseErr = data['error_string'];
      }

    }, err => {
      this.errors = err;
    });

  }


  openTellUs(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
    }, (reason) => {
    });

  }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
      this.clearPresistFormData();

    } catch (e) {

    }
  }

  hideLoginPass() {
    this.loginPassHide == true ? false : true;
  }

  hideRPass() {
    this.loginPassHide == true ? false : true;
  }

  rPassdHideFn() {
    this.rPassdHide == true ? false : true;
  }

  rConfirmPassHideFn() {
    this.rConfirmPassHide == true ? false : true;
  }

  forgotPasswordPage() {
    this.forgotPasswordComponent = true;
    this.loginPage = false;
    this.responseErr = '';

   // this.googleEvent.eventEmitter(this.locale + ' - ' + this.timezoneForGA, 'Forgot', 'Forgot button click');
  }

  forgotPassword(resent = '') {

    console.log(this.forgotPasswordForm);
    this.errors = { errors: {} };
    this.isForgotSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    const credentials = this.forgotPasswordForm.value;

    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if ((this.forgotPasswordForm.get('emailaddress')) && reg.test(this.forgotPasswordForm.get('emailaddress').value) == false) {
      delete credentials['emailaddress'];
      credentials['mobile'] = this.forgotPasswordForm.get('emailaddress').value;
      this.mobileNumber = credentials['mobile'];
      this.googleEvent.eventEmitter('Forgot', 'Forgot' , this.locale + ' - ' + this.timezoneForGA, 0 , this.mobileNumber , this.mobileNumber+ ' - ' + this.locale + ' - ' + this.timezoneForGA+ ' -Forgot button click');

    } else {
      delete credentials['mobile'];
      credentials['emailaddress'] = this.forgotPasswordForm.get('emailaddress').value;
      this.emailId = credentials['emailaddress'];
      this.googleEvent.eventEmitter('Forgot', 'Forgot' , this.locale + ' - ' + this.timezoneForGA, 0 , this.emailId, this.emailId+ ' - ' + this.locale + ' - ' + this.timezoneForGA + 'Forgot button click');

    }




    credentials.catlogue = CatlougeTypeConstants.catlogue;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.forgotPassword(urlEncodedBodyData).subscribe(data => {

      if (data['error_code'] == '201' || data['error_code'] == "428") {
        localStorage.setItem('sessionId', data['result']['sessionId'])
        this.forgotPasswordResponse = data;
        this.otpInputbox = true;
        this.forgotPasswordComponent = false;
        // this.responseErr = resent == ''?'':'';
        this.responseErr = data['error_string'];

        this.resendOtpTimer();
      }
      else if (data['error_code'] == '429') {
        this.responseErr = data['error_string'];
        this.responseErr.replace("Contact", "<a href='contactus'>Contact</a>");
      }
      else if (data['error_code'] == '428') {
        this.responseErr = data['error_string'];
        // if(data['error_string'] == 'OTP successfully resent to 9728488343. If OTP not received, please go back and try again or Contact for support.'){

        // }



        this.otpInputbox = true;
        this.forgotPasswordComponent = false;
      }
      else {
        this.responseErr = 'User not Found'
      }
    });

  }


  submitOtp() {

    this.isOtpSubmitted = true;

    const credentials = this.otpForm.value;

    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if ((this.forgotPasswordForm.get('emailaddress')) && reg.test(this.forgotPasswordForm.get('emailaddress').value) == false) {
      credentials['mobile'] = this.forgotPasswordForm.get('emailaddress').value;
    } else {
      credentials['emailaddress'] = this.forgotPasswordForm.get('emailaddress').value;
    }

    if (this.otpForm.invalid) {
      return;
    }

    console.log(this.otpForm);

    credentials.catlogue = CatlougeTypeConstants.catlogue;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.verifyOtp(urlEncodedBodyData).subscribe(data => {

      if (data['error_code'] == '200' && data['result']) {
        this.resetPassword = true;
        this.otpInputbox = false;
        this.responseErr = '';
      } else {
        this.responseErr = 'Incorrect OTP. Please try again.';
        this.resendOTPstatus = false;

      }
    });
  }

  submitResetPassword() {

    // if(this.timezone == 'Asia/Calcutta'){
    //   this.resetPasswordForm.patchValue({
    //     "mobile":this.forgotPasswordForm.value.mobile
    //   });

    // } else {
    //   this.resetPasswordForm.patchValue({
    //     "emailaddress":this.forgotPasswordForm.value.emailaddress,
    //   });

    // }
    const credentials = this.resetPasswordForm.value;


    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if ((this.forgotPasswordForm.get('emailaddress')) && reg.test(this.forgotPasswordForm.get('emailaddress').value) == false) {
      credentials['mobile'] = this.forgotPasswordForm.get('emailaddress').value;
    } else {
      credentials['emailaddress'] = this.forgotPasswordForm.get('emailaddress').value;
    }



    // if(this.timezone == 'Asia/Calcutta'){
    //   delete credentials['emailaddress'];
    //   credentials.mobile = this.forgotPasswordForm.value.mobile;
    // } else {
    //   delete credentials['mobile'];
    //   credentials.emailaddress = this.forgotPasswordForm.value.emailaddress;
    // }

    this.isResetSubmitted = true;
    console.log(this.resetPasswordForm);
    if (this.resetPasswordForm.invalid) {
      return;
    }

    credentials.pass = this.resetPasswordForm.value.pass.trim();
    credentials.cpass = this.resetPasswordForm.value.cpass.trim();
    console.log(credentials.pass.length);
    console.log(credentials.cpass.length);
    if (credentials.pass.length < 8 || credentials.cpass.length < 8) {
      this.responseErr = 'Password must be at least 8 characters'
      return;
    } else {
      this.responseErr = ''
    }


    credentials.catlogue = CatlougeTypeConstants.catlogue;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.resetPassword(urlEncodedBodyData).subscribe(data => {

      if (data['error_code'] == '200') {
        this.resetPasswordSuccess = true;
        this.resetPassword = false;
        this.responseErr = '';
        if (this.timezone === 'Asia/Calcutta') {
          this.resetPasswordForm.patchValue({
            "mobile": this.forgotPasswordForm.value.mobile
          });

        } else {
          this.resetPasswordForm.patchValue({
            "emailaddress": this.forgotPasswordForm.value.emailaddress,
          });

        }

      } else {
        this.responseErr = data['error_string'];

      }
    });
  }

  resendOTP() {
    this.forgotPassword('resent');
    this.resendOTPstatus = true;
    // this.responseErr = 'OTP has been sent again';
  }
  resetLoginPage() {
    this.responseErr = ''
    this.loginPage = true;
    this.resetPasswordSuccess = false;
    this.clearPresistFormData();
  }

  setLoginPage() {
    this.loginPage = false;
    this.signUpPageStatus = true;
  }


  // getPlaylist() {
  //   let tempGetPlay = {
  //     catlogue: CatlougeTypeConstants.catlogue,
  //     plateform: "web",
  //     action: "getplaylist",
  //   }

  //   let urlEncodedPlayListParams = this.userService.convertoJSONToUrlEncoded(tempGetPlay);

  //   this.playListService.getPlaylist(urlEncodedPlayListParams).subscribe(data => {
  //     // this.playList = data;

  //     if (data && data.result) {
  //       localStorage.setItem('playListId', data?.result[0]?.playlistid);
  //       this.userService.loginStatus(true);
  //       this.closebutton.nativeElement.click();
  //       this.closeModal();
  //       this.responseErr = ''
  //       console.log(this.currentRoute);
  //       if (Object.keys(this.commonService.getAssetsData()).length > 0){
          
  //       this.afterLoagindata = this.commonService.getAssetsData();
  //       this.landingPage = this.afterLoagindata.landing_page;
  //       if(this.landingPage != undefined){
  //       switch (this.landingPage) {
  //         case '__url':
  //           this.landingUrl();
  //           break;
  //         case '__app_page':
  //           this.landingAppPage();
  //           break;
  //         case '__sub_category':
  //           this.landingSubCategory();
  //           break;
  //         case '__asset':
  //           this.landingAsset();
  //           break;
  //         case '__webviewurl':
  //           this.landingVotingPage();
  //           break;  
  //         default: this.router.navigateByUrl('/');
  //           break;
    
  //       }
  //     }else{
  //        this.commonService.goToDetail(this.commonService.getAssetsData(), '', 1);
  //     }
  //     }else{
  //         window.location.reload();
  //       }
  //     }

  //   });
  // }

  getPlaylist() {
    let tempGetPlay = {
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getplaylist",
    };
  
    let urlEncodedPlayListParams = this.userService.convertoJSONToUrlEncoded(tempGetPlay);
  
    this.playListService.getPlaylist(urlEncodedPlayListParams).subscribe(data => {
      if (data && data.result) {
        localStorage.setItem('playListId', data.result[0]?.playlistid);
        this.userService.loginStatus(true);
        this.closebutton.nativeElement.click();
        this.closeModal();
        this.responseErr = '';
  
        // Check for redirectTo query param
        const urlParams = new URLSearchParams(window.location.search);
        const redirectTo = urlParams.get('redirectTo');
  
        if (redirectTo) {
          this.router.navigateByUrl(redirectTo);
          // window.location.href = redirectTo;
        } else {
          if (Object.keys(this.commonService.getAssetsData()).length > 0) {
            this.afterLoagindata = this.commonService.getAssetsData();
            this.landingPage = this.afterLoagindata.landing_page;
  
            if (this.landingPage != undefined) {
              switch (this.landingPage) {
                case '__url':
                  this.landingUrl();
                  break;
                case '__app_page':
                  this.landingAppPage();
                  break;
                case '__sub_category':
                  this.landingSubCategory();
                  break;
                case '__asset':
                  this.landingAsset();
                  break;
                case '__webviewurl':
                  this.landingVotingPage();
                  break;  
                default:
                  this.router.navigateByUrl('/');
                  break;
              }
            } else {
              this.commonService.goToDetail(this.commonService.getAssetsData(), '', 1);
            }
          } else {
            window.location.reload();
          }
        }
      } else {
        window.location.reload();
      }
    });
  }
  
  requestForOtp() {

    // this.resendOTPstatus = true;
    let otpObj = {
      send_for: 'signup'
    }
    this.userService.requestOtp(otpObj).subscribe(data => {
      this.error_code = data['error_code'];
      this.responseErr = data['error_string'];
      console.log(data['error_string']);
      console.log(data['error_code']);
      if (data.error_code == "201") {
        this.resendOtpTimer();
      }
      else if (data['error_code'] == "428") {
        // this.maxOtpAttempt = 0;
        this.responseErr = data['error_string'];
        console.log(data['error_string']);
        console.log(data['error_code']);

      }
      else if (data['error_code'] == "429") {
        // this.maxOtpAttempt = 0;
        this.responseErr = data['error_string'];


      } else {

      }

    });
  }

  resendOtpTimer() {
    this.otpInt = resendOtpTimer.otpTimer;
    this.maxOtpAttempt--;
    console.log(this.maxOtpAttempt);
    let timerInterval = setInterval(() => {

      if (this.otpInt == 0) {
        clearInterval(timerInterval);
      }
      this.otpInt--;
      console.log(this.maxOtpAttempt);
      console.log(this.otpInt);
    }, 1000);

  }

  reloadPage() {
    window.location.reload();
  }

  clearPresistFormData() {
    // this.loginForm.reset();    
    this.forgotPasswordForm.reset();
    this.otpForm.reset();
    this.resetPasswordForm.reset();
    this.isLoginSubmitted = false;
    this.isForgotSubmitted = false;
    this.isResetSubmitted = false;

  }


  landingUrl() {
    let url = this.afterLoagindata.url;
    window.open(url, '_blank').focus();
  }
  landingAppPage() {
    let appPage = this.afterLoagindata.app_page;

    switch (appPage) {
      case 'about':
        this.router.navigateByUrl('/aboutus');
        break;
      case 'faq':
        this.router.navigateByUrl('/contactus');
        break;
      case 'help':
        this.router.navigateByUrl('/contactus');
        break;
      case 'my-profile':
        this.router.navigateByUrl('/myaccount');
        break;
      case 'theme-setting':
        this.router.navigateByUrl('/');
        break;
      case 'subscription':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'activation-code':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'watchlist':
        this.router.navigateByUrl('/playlist');
        break;

      default: this.router.navigateByUrl('/');
        break;

    }
  }
  landingSubCategory() {
    if (this.afterLoagindata.sub_category_id != '' || this.afterLoagindata.sub_category_id != undefined) {
      // let sub_category_id = this.data.sub_category_id;
      // let viewalltabDataParams = {
      //   "sub_category_id" :sub_category_id,
      //   "type" : 'home'
      // }

      let viewAlldParams = {
        categoryId: this.afterLoagindata.sub_category_id,
        // categoryType: this.data.category_type
      }

      viewAlldParams['type'] = this.afterLoagindata.page_tab_slug;
      // viewAlldParams['sub_category_id'] = this.data.sub_category_id;
      // viewAlldParams['banner'] = "campaign_banner";
      localStorage.setItem('banner','campaign_banner');
      // this.router.navigate(['viewall'],{queryParams:viewalltabDataParams});
      this.router.navigate(['/subcategory'], { queryParams: viewAlldParams })

    } else {
      let categoryId = this.afterLoagindata.category_id;
      let viewalltabDataParams = {
        banner: "campaign_banner",
        "categoryId" :categoryId,
        "type" : 'home'
      }

      this.router.navigate(['/subcategory'], { queryParams: viewalltabDataParams })

      // this.router.navigate(['viewall'],{queryParams:viewalltabDataParams});
    }
  }
  landingAsset() {
    let assetData =  this.afterLoagindata.asset;
    this.router.navigate(['detail/' + this.afterLoagindata.asset.type + '/' + this.afterLoagindata.asset.code + '/' + this.afterLoagindata.asset.type])
    // code: "TSH338" type: "tvshow"
    // code: TSH336
    // catlogue: 5f48bc1eb9124f41a16c5712
    // platform: web
    // type: tvshow
  }

  landingVotingPage() {
    let url = this.afterLoagindata.webviewurl;
    const splitArr = url.split("cid=");
    let eventid =splitArr[1];
    let sessId = localStorage.getItem('sessionId');
    window.open('content/'+eventid+'/'+ sessId, '_blank');
  }
}
