
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { environment, } from '../environments/environment.prod';
import { EventTrackingService } from './services/google/event-tracking.service';
import { PageTitleService } from './services/title/page-title.service';
import { filter, map } from 'rxjs/operators';
declare let gtag: Function;
import { LocalStorageService } from './core/services/local-stroage.service'
import { UserTypeConstants, authKey } from "src/app/core/constants/userType.constant";
import * as e from 'express';
declare var $: any;

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  public spinkit = Spinkit;
  skChasingDots = 'sk-chasing-dots'
  skCubeGrid = 'sk-cube-grid'
  skDoubleBounce = 'sk-double-bounce'
  skRotatingPlane = 'sk-rotationg-plane'
  skSpinnerPulse = 'sk-spinner-pulse'
  skThreeBounce = 'sk-three-bounce'
  skWanderingCubes = 'sk-wandering-cubes'
  skWave = 'sk-wave'
  activeComponent: string;

  constructor(
    private router: Router,
    private googleEventTracking: EventTrackingService,
    private pageTitleService: PageTitleService,
    private activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) {

    if (window.performance.navigation.type == 1) {
      return;
    }else{
      localStorage.removeItem('slugName');
      // window.onbeforeunload = () => {
      //   localStorage.removeItem('slugName');
      // }
    }
    
    window.onbeforeunload = () => {
      // localStorage.removeItem('slugName');
    }
    // location.onPopState(() => {
    //   console.log(`pressed back in add!!!!!`);
    //   //this.router.navigateByUrl(‘/multicomponent’);
    //   //history.forward();
    //   });
    if (localStorage.getItem('debug') == 'false') {
      window.console.log = function () { };   // disable any console.log debugging statements in production mode
      window.console.error = function () { };
    } else {

    }
    if (!localStorage.getItem('isAuthenticated')) {

      localStorage.setItem('sessionId', authKey.defaultAuthKey);
    }

    // if(environment.production ) {
    // } else {
    //   // window.console.log = function () { };   // disable any console.log debugging statements in production mode
    //   window.console.error = function () { };
    // }

    

  }

  ngOnInit() {
    const appTitle = this.pageTitleService.getTitle();
    // let checkUrlForVoting = this.router.url;
    // if(checkUrlForVoting.includes('content')){
    //   $("body").css({"background": "rgb(24,27,90)",
    //     "filter" : "progid:DXImageTransform.Microsoft.gradient(startColorstr='#181b5a',endColorstr='#16242f',GradientType=1)"});
    // }
    
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }

          this.activeComponent = child.snapshot.data['componentName'];
          console.log(this.activeComponent);

          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        console.log(ttl);
        this.pageTitleService.setTitle(ttl);
      });



    this.router.events.subscribe((evt) => {

      if (evt instanceof NavigationEnd) {
        this.googleEventTracking.routeTrack(evt.urlAfterRedirects);
      }


      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

}
