import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('footer').classList.remove("footerhide");
    }, 1500);
  }

  flipLogoPlay(){
    console.log('Logo')
  }

  flipLogoApp(){

  }

}
