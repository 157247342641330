import { Component, OnInit, Input, Output, Inject, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import * as $ from 'jquery' 
import { AssetsService } from "../../core/services/assets.service";
import { Errors, UserService } from '../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'; // add this 1 of 4
import { CatlougeTypeConstants } from "src/app/core/constants/index";
import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import { CommonService } from "src/app/core/services/common.service";
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { DOCUMENT } from '@angular/common';
import { LocalStorageService } from '../../core/services/local-stroage.service'
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { customOptionsForBigSlider, customOptionsForEightMiniSlider, customOptionsForFourMiniSlider, customOptionsForSevenMiniSlider, customOptionsForSixMiniSlider, customOptionsForThreeMiniSlider } from '../owl-slider-config';
declare var $: any;

@Component({
  selector: 'app-owl-slider',
  templateUrl: './owl-slider.component.html',
  styleUrls: ['./owl-slider.component.css'],
  providers: [UserService, PlayListService]
})
export class OwlSliderComponent implements OnInit {

  @Input() owlOption: OwlOptions;
  @Input() data: any = {};
  @Input() owlSliderData;
  @Input() page_view: string;
  @Input() languages: any;


  customOptionsForBigSlider = customOptionsForBigSlider;
  customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
  customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
  customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
  customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
  customOptionsForEightMiniSlider = customOptionsForEightMiniSlider;

  @Output() deleteRecentWatch: EventEmitter<any> = new EventEmitter<any>();
  userInfo: any;
  currentTime: number = Math.floor(Date.now() / 1000);
  totalPlayed: any = 0;
  routeName: string;
  categoryId: any;
  playList: any;
  owlSlides: boolean = false;
  setPlayListItem: any;
  @ViewChild('closeAddtoWatchListModal') closeAddtoWatchListModal;
  @ViewChild('closeContinueModal') closeContinueModal;
  @ViewChild('addToWatchListSuccess') addToWatchListSuccess;
  @ViewChild('itemAddedIntoWach') itemAddedIntoWach: ElementRef;
  @ViewChild('removeToWatchListSuccessModal') removeToWatchListSuccessModal;

  progrssBarValue: number = 0;
  progressInc: number = 0;
  playListId: string;
  error_String: any;
  error_code: any;
  removeItemFromDetail: any;
  assets_hover: boolean;
  streamInfo: any;
  addedAssetIndex: any;
  playListAvailable: boolean;
  playlist: any;
  tempAsset_id: any;
  deletedItem: any;
  deleteAsset_id: any;
  thumbnailType: any;
  thumbnailClass: string;
  notFoundImg:string = 'landscape';
  assetImgParam: any;
  showViewAll: number = 4;
  isCircle: any;
  enlargedCircle: any;
  isCloudFrontType: boolean = false;

  // public bannerResult:any = null;
  constructor(
    public AssetsService: AssetsService,
    public UserService: UserService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    public playListService: PlayListService,
    public commonService: CommonService,
    public pageTitleService: PageTitleService,
    @Inject(DOCUMENT) private document: Document,
    public localStorageService: LocalStorageService,
    private googleEvent: EventTrackingService


  ) {

    // this.customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
    // this.customOptionsForSevenMiniSlider['slides'] = 7;


    // this.customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
    // this.customOptionsForSixMiniSlider['slides'] = 6;

    // this.customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
    // this.customOptionsForThreeMiniSlider['slides'] = 3;

    // this.customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
    // this.customOptionsForFourMiniSlider['slides'] = 4;

    this.router.events.subscribe((res) => {

      console.log(res);
    });
    
    // this.thumbnailType = 'landscape';
  }

  ngOnInit() {
    
    this.router.events.subscribe((res) => {
      console.log(this.router.url, "Current URL");
      this.routeName = this.router.url;
      console.log(this.routeName);
    });


    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // this.playListId = localStorage.getItem('playListId');

    if (this.userInfo) {
      this.playListId = localStorage.getItem('playListId');
    }
    
    if(this.commonService.filterType ==1){
      this.isCloudFrontType = true ;
      this.isCircle = {"roundCrop": true, 'width':191,'height':191};
      this.enlargedCircle = {"roundCrop": true, 'width':230,'height':230};
    }else{
      this.isCircle = {"radius":'max','width':191,'height':191};
      this.enlargedCircle = {"radius":'max','width':230,'height':230};
      this.isCloudFrontType = false;
    } 
    this.data = this.data;
    this.owlSliderData = this.owlSliderData;
    this.thumbnailType = this.data.thumbnail_type.slug;

    if(Object.keys(this.data.thumbnail_type).length === 0){
      this.thumbnailType = 'landscape';
    }else{
      this.thumbnailType = this.data.thumbnail_type.slug;
    }

    // customOptionsForFourMiniSlider
    // customOptionsForSevenMiniSlider
    // customOptionsForSixMiniSlider
    // customOptionsForThreeMiniSlider


    switch (this.thumbnailType) {
      case 'landscape':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam  = {'width':292,'height':164};
        this.thumbnailClass = 'landscape_assets';
        this.notFoundImg = 'landscape';
        this.showViewAll = 4;
        break;
      case 'enlarged-landscape':
        this.owlOption = customOptionsForThreeMiniSlider;
        this.assetImgParam  = {'width':390,'height':220};
        this.thumbnailClass = 'enlarged_landscape_assets';
        this.notFoundImg = 'enlarged_landscape';
        this.showViewAll = 3;
        break;
      case 'stretched-landscape':
        this.owlOption = customOptionsForThreeMiniSlider;
        this.assetImgParam  =  {'width':390,'height':170};
        this.thumbnailClass = 'stretched_landscape_assets';
        this.notFoundImg = 'stretched_landscape';
        this.showViewAll = 3;
        
        break;
      case 'portrait':
        this.owlOption = customOptionsForEightMiniSlider;
        this.assetImgParam  = {'width':191,'height':286}; 
        this.thumbnailClass = 'portrait_assets';
        this.notFoundImg = 'portrait';
        this.showViewAll = 8;
        break;
      case 'square':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam  =  {'width':161,'height':161};
        this.thumbnailClass = 'square_assets';
        this.notFoundImg = 'square';
        this.showViewAll = 7;
        break;
      case 'enlarged-square':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam  =  {'width':292,'height':292};
        this.thumbnailClass = 'enlarged_square_assets';
        this.notFoundImg = 'enlarged_square';
        this.showViewAll = 4;
        break;
      case 'circular':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam  = this.isCircle;
        this.thumbnailClass = 'circular_assets';
        this.notFoundImg = 'circle';
        this.showViewAll = 7;
        break;
      case 'enlarged-circle':
        this.owlOption = customOptionsForSixMiniSlider;
        this.assetImgParam  = this.enlargedCircle;
        this.thumbnailClass = 'enlarged-circular_assets';
        this.notFoundImg = 'circle';
        this.showViewAll = 5;
        break;  

      default: this.thumbnailClass = 'landscape_assets';
               this.assetImgParam  = {'width':330,'height':180};
               this.owlOption = customOptionsForFourMiniSlider;
               this.showViewAll = 4;
               this.notFoundImg = 'landscape';
        break;
    }
    console.log(this.data);
    this.owlSlides = this.data.assets && this.data.assets.length >= 4 ? true : false;
    // this.getWatchList();
    if (this.data && this.data.assets)
      this.data.assets.forEach((ele, index) => {
        // this.data.assets[index].genre_text='Romance'
        if (ele.type == 'livetv') {

          let endTime = moment.unix(parseInt(ele.end_timestamp)).format();
          let currentTime = moment().format();
          let diff = moment.duration(moment(endTime).diff(moment(currentTime))).minutes();
          this.data.assets[index].totalPlayed = ele.duration - diff;

        }

      });

  }


  liniarProgressBar() {
    this.progressInc = 0;
    let progrssInterval = setInterval(() => {
      this.progrssBarValue = this.progressInc * 10;

      if (this.progressInc >= 10) {
        clearInterval(progrssInterval);
      }
      this.progressInc++;
      console.log(this.progrssBarValue);
    }, 500)

  }

  setDeleteItemForDelete(item) {
    localStorage.setItem('deleteItem', item);
  }

  deleteWatch(e, item) {
    e.stopPropagation();
    this.removeItemFromDetail = item;
    this.deleteRecentWatch.emit(item);
    this.closeContinueModal.nativeElement.click();
    setTimeout(() => {
      this.document.getElementById('removeContinueModal').click();
    }, 1500);

  }

  openMOdelWindow(content) {
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    // }, (reason) => {
    // });

  }
  redirectUrl(data, content, jwPlayerDom, event) {
    // event.stopPropagation();
    this.userInfo = localStorage.getItem('userInfo');


    if (this.userInfo) {
      let itemForDetailPage = {
        code: data.code,
        type: data.type,
      }
      let pagename = this.strToLower(this.data.category_name);
      this.page_view = this.page_view + '_' + pagename.split(/\s/).join('');

      if (data.asset_cat_type == 'recentlywatched') {
        // localStorage.setItem('stop', data.stop?data.stop:0);
        // // itemForDetailPage['stop'] = data.stop?data.stop:'';
        // this.streamInfo = data;
        // console.log(this.streamInfo);
        // console.log('recentlywatched');
        // // this.modalService.open(jwPlayerDom, {ariaLabelledBy: 'modal-basic-title',windowClass: '_play_modal_jw jwfullscreen', size: 'xl', backdrop: 'static' }).result.then((result) => {
        // // }, (reason) => {
        // // });

        // this.modalService.open(jwPlayerDom, {ariaLabelledBy: 'modal-basic-title',windowClass: '_play_modal_jw jwfullscreen', backdrop: 'static', keyboard : false }).result.then((result) => {
        // }, (reason) => {
        // });
        this.commonService.goToDetail(data, this.page_view);

      } else {
        // console.log('sahil '+this.page_view);
        this.commonService.goToDetail(data, this.page_view);

      }
      // this.router.navigate(['detail/'+data.name+'/'+itemForDetailPage.code+'/'+ itemForDetailPage.type])
    } else {
      this.commonService.setBeforeLoginState(data);
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }


  viewall(data) {
    // console.log(this.router.url);
    console.log(data);
    let viewAlldParams = {
      categoryId: data._id
    }

    if(data.category_code =="HCTG043") {
      viewAlldParams['type'] = 'livetv'
    } 
    console.log(this.languages);

    this.routeName = this.router.url;
    if (this.routeName == '/') {
      viewAlldParams['homecatid'] = data._id;
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }
    if (this.routeName == '/livetv') {
      viewAlldParams['genre'] = data.genre_id;
      viewAlldParams['type'] = 'livetv';
      viewAlldParams['language_id'] = this.languages.toString();
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }

    // data.genre_id
    if (this.routeName == '/movies') {
      viewAlldParams['genre'] = data.genre_id;
      viewAlldParams['type'] = 'movie';
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }

    if (this.routeName == '/tvshows') {

      viewAlldParams['show_id'] = data._id;
      viewAlldParams['genre'] = data.genre_id;
      viewAlldParams['type'] = 'tvshow';
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;

    }

    if (this.routeName == '/videos') {
      viewAlldParams['category_code'] = data.category_code;
      viewAlldParams['type'] = 'vod';
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }
    viewAlldParams['type'] = data.tab_slug;
    this.router.navigate(['/viewall'], { queryParams: viewAlldParams })
  }


  AddToPlayListItems(e, item, content) {

    console.log(item);
    e.stopPropagation();
    this.routeName = this.router.url;
    if (this.userInfo && Object.keys(this.userInfo.result).length > 0) {
      this.setPlayListItem = item;
      let userUnique = '';
      if (this.userInfo.result.register_by == "mobile") {
        userUnique = this.userInfo.result.mobile;
      } else {
        userUnique = this.userInfo.result.emailaddress;
      }

      this.googleEvent.eventEmitter('Watchlist' , 'Watchlist' , userUnique + '-' + item.code + '-' + item.name, 0, userUnique, userUnique + '-' + item.code + '-' + item.name);

      let addToplayListParams = {
        playlistid: this.playListId ? this.playListId : '',
        catlogue: CatlougeTypeConstants.catlogue,
        plateform: "web",
        playingtype: "Video",
        assetId: item.asset_mongo_id ? item.asset_mongo_id : item._id,
        show_id: item.tvshowId ? item.tvshowId : '',
        assetType: item.type ? item.type : '',
        action: "saveAssetInPlaylist",
      }


      if (this.routeName == '/') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['asset_mongo_id'] == item.asset_mongo_id)
      } else if (this.routeName == '/tvshows') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['asset_mongo_id'] == item.asset_mongo_id)
      } else if (this.routeName == '/movies') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['_id'] == item._id)
      } else if (this.routeName == '/livetv') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['_id'] == item._id)
      }

      let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(addToplayListParams);
      if ((item.asset_mongo_id ? item.asset_mongo_id : item._id) != undefined) {

        this.playListService.addToPlayList(urlEncodedToplayListParams).subscribe((data) => {
          this.error_code = data.error_code;
          if (data.error_code == '200') {
            console.log(this.addedAssetIndex);
            this.data.assets[this.addedAssetIndex].is_watchlist_added = true;
            // this.itemAddedIntoWach.nativeElement.innerHTML = `Item Successfully added ${this.setPlayListItem?.name} into your Watchlist.`;  
            this.document.getElementById('itemAddSuccessMsg').innerHTML = `Successfully added to Watchlist.`;

          }
          if (data.error_code == '209') {
            this.data.assets[this.addedAssetIndex].is_watchlist_added = false;
            console.log(item);
            this.deleteWatchListItem(item);
          }
          this.addToWatchListSuccess.nativeElement.click();
          setTimeout(() => {
            this.document.getElementById('closeAddToWatchList').click();
          }, 1500);

          //this.toastr.success('Success!', '', { timeOut: 2000 });
          // this.closeAddtoWatchListModal.nativeElement.click();

        });
      } else {
        // this.closeAddtoWatchListModal.nativeElement.click();
      }


    } else {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }


  item_hover_enter() {
    // console.log("item hover enter");
    this.assets_hover = true;
  }

  item_hover_leave() {
    // console.log("item hover leave");
    this.assets_hover = false;
  }
  strToLower(str: string) {

    if (str) {
      return str.toLowerCase();
    }
  }



  getWatchList() {

    this.playListId = localStorage.getItem('playListId');
    let temp = {
      playlistid: this.playListId ? this.playListId : '',
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getPlayListAsset",
    }

    let urlEncodedPlayListAssetsParams = this.UserService.convertoJSONToUrlEncoded(temp);
    this.playListService.getPlaylist(urlEncodedPlayListAssetsParams).subscribe(data => {
      console.log(data);
      if (data && data.result && Array.isArray(data.result)) {
        if (Array.isArray(data.result)) {
          this.playListAvailable = true;
          this.playlist = data.result;
          console.log(this.playlist);
          // this.check_into_watchlist()
          return this.playlist;
        }
      }
    });

  }


  check_into_watchlist() {
    this.routeName = this.router.url;
    console.log(this.routeName);
    this.data.assets.forEach((tempAsset, tempIndex) => {

      if (this.routeName == '/') {
        this.tempAsset_id = tempAsset.asset_mongo_id
      } else if (this.routeName == '/tvshows') {
        this.tempAsset_id = tempAsset.asset_mongo_id
      } else if (this.routeName == '/movies') {
        this.tempAsset_id = tempAsset._id;
      } else if (this.routeName == '/livetv') {
        this.tempAsset_id = tempAsset._id;
      }

      if (this.playlist.findIndex(tempObj => tempObj._id == this.tempAsset_id) != -1) {
        this.data.assets[tempIndex].is_watchlist_added = true;
      } else {
        this.data.assets[tempIndex].is_watchlist_added = false;
      }
      console.log(this.data.assets[tempIndex].is_watchlist_added);
    });
    console.log(this.data);
  }



  deleteWatchListItem(item) {

    this.deletedItem = item;
    if (this.routeName == '/') {
      this.deleteAsset_id = item.asset_mongo_id
    } else if (this.routeName == '/tvshows') {
      this.deleteAsset_id = item.asset_mongo_id
    } else if (this.routeName == '/movies') {
      this.deleteAsset_id = item._id;
    } else if (this.routeName == '/livetv') {
      this.deleteAsset_id = item._id;
    }


    let deletePlayListAssets = {
      playlistid: this.playListId,
      assetId: this.deleteAsset_id,
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      // playlist_asset_id:item._id,
      action: "deleteAssetFromPlaylist"
    }

    let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(deletePlayListAssets);
    this.playListService.deleteFromPlayList(urlEncodedToplayListParams).subscribe((data) => {
      this.document.getElementById('itemAddSuccessMsg').innerHTML = `Successfully removed From Watchlist.`;

      // console.log(data);
      // let index = this.playlist.findIndex(playItem => playItem._id === item._id);
    });

  }

}


