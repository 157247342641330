<div class="maincontentarea mt20">
    <div class="container">
       <div class="row">
           <div class="col-sm-12">               
               <div class="_about_content">
                  <h2>ABOUT US</h2>
                   <div class="_about_inner_c">
                        <p>Zonet Empai is the Official OTT platform for Zonet TV, a Mizo satellite TV channel from Zonet TV Pvt Ltd which is a Mizoram based cable TV network. The site showcases our best shows, events, interviews and documentaries.</p>
                        <p>Zonet Empai offers a compelling end-user experience to watch Movies, Shows and Events online and gives you a wide range of entertainment with high quality viewing experience and rich video quality at affordable prices.</p>
                        <p>Watch a gamut of Mizo Movies, Original TV shows, Short films, Live events and more, anytime, based on your preference.</p>
                        <p>An on-demand video streaming platform for viewers all across the globe.  A subscription based model containing hours of recreational video content added at regular intervals. A pioneer in bringing Mizo entertainment on the OTT platform, Zonet Empai comes with a plethora of options covering short films, TV shows, interviews, documentaries, events and more.</p>
                   </div>
               </div>
           </div>
       </div>
    </div>
</div>