<p class="_custom_progress">
  <ngb-progressbar class="_custom_progress" type="info" id="nexg_ngb-progressbar" [value]="progrssBarValue">
  </ngb-progressbar>
</p>




<div class="maincontentarea _beforeLoad" id="maincontentarea" style="margin: 0;">

  <div class="maincontentarea" style="margin:0;">

    <div *ngIf="owlBannerData.banner_result.banner.length>0">
      <app-owl-banner-slider [owlOption]="customOptionsForBigSlider" [data]="owlBannerData.banner_result.banner"
        [page_view]="'Screen_Home_Banner'">
      </app-owl-banner-slider>
    </div>

    <div class="mt40" *ngIf="owlBannerData.banner_result.banner.length<=0">

    </div>


    <!-- <div  *ngIf="getCurrentSlug == 'home'"> -->
    <div *ngIf="owlBannerData.total_count>0">
      <div *ngFor="let item of owlBannerData?.result; let i=index">
        <div *ngIf="item.tab_slug == showContinueWatching">
          <app-owl-slider *ngIf="item?.assets_total_cnt>0" (deleteRecentWatch)="deleteRecentWatchItem($event)"
            [owlOption]="customOptionsForFourMiniSlider" [data]="item" [page_view]="'Screen_Home'"></app-owl-slider>
        </div>
      </div>
    </div>
    <!-- </div> -->


    <!-- <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()">
      <div>
        <div *ngFor="let item of owlSliderData?.result; let i=index">

          <asset-row *ngIf="item?.sub_category_count>0" [data]="item"></asset-row>

          <app-owl-slider *ngIf="item?.sub_category_count == 0"
            (deleteRecentWatch)="deleteRecentWatchItem($event)" [owlOption]="customOptionsForSevenMiniSlider"
            [owlSliderData]="scrollData" [data]="item" [page_view]="'Screen_Home'"></app-owl-slider>

        </div>
      </div>
    </div> -->

    <div class="search-results" infiniteScroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="25"
      (scrolled)="onScroll()">

      <div>
        <div *ngFor="let item of owlSliderData?.result; let i=index">
          <div *ngIf="item.category_disp_type == '' && item.category_disp_type != 'campaign_banner'">
            <!-- <asset-row *ngIf="item?.sub_category_count>0" [data]="item"></asset-row> -->
            <app-category *ngIf="item?.sub_category_count>0" [data]="item"></app-category>

            <app-owl-slider *ngIf="item?.sub_category_count == 0" (deleteRecentWatch)="deleteRecentWatchItem($event)"
              [owlOption]="customOptionsForSevenMiniSlider" [owlSliderData]="scrollData" [data]="item"
              [page_view]="'Screen_Home'"></app-owl-slider>
          </div>
          <div
            *ngIf="item.category_disp_type != '' && item.category_disp_type == 'campaign_banner' && (this.isCloudFrontType == true ? item.thumbnail_type.slug=='stretched-landscape' && item.category_image_cloudfront_url!='' || item.thumbnail_type.slug=='banner' && item.thumbnail_banner_stretched_landscape_cloudfront_url!='' ||  item.thumbnail_type.slug!='stretched-landscape' && item.thumbnail_type.slug!='banner' && item.thumbnail_cloudfront_url!='' : item.thumbnail_type.slug=='stretched-landscape' && item.category_image!='' || item.thumbnail_type.slug=='banner' && item.thumbnail_banner_stretched_landscape!='' ||  item.thumbnail_type.slug!='stretched-landscape' && item.thumbnail_type.slug!='banner' && item.thumbnail!='')">
            <app-campaign-banner *ngIf="item.category_disp_type != '' && item.category_disp_type == 'campaign_banner'"
              [data]="item"></app-campaign-banner>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="loader"></div> -->

    <!-- <div *ngIf="loadMore" class="loader" id="loadMore"
      style="color: white; text-align: center;font-size: x-large;">
    </div> -->





    <div *ngIf="owlSliderData.result.length == 0">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="_no_data_f">
              <h1>No Data Found</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <section class="banner_img add" *ngIf="showAddBanner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="p_banner">
              <dfp-ad target="_blank" adUnit="/30392581/platform8_banner_970x90" responsive>
                <dfp-size [width]="970" [height]="90"></dfp-size>
              </dfp-ad>

            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>

  <!-- <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
    
  </div> -->