<div class="maincontentarea">
    <div class="container">
        <div class="terms">
            <h1 class="text-center">PRIVACY STATEMENT</h1>
            <h2>INFORMATION WE COLLECT FROM YOU</h2>
            <p>In order to provide our services, we collect various unique identifiers pertaining to you. Personal Information is information that you provide to us which personally identifies you, such as your name, email address, or other data that can be reasonably linked to such information by us, to associate with your profile with us.</p>
            <p>Information which you provide to us:</p>
            <p>When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.</p>
            <p>When you create an account with us, you provide us with personal information that includes your email address/mobile number and a password, for the provisioning of an account and access to it. We also collect any further information including your name, location, profile image on the social media platforms of Facebook, Twitter, and Google where you make such connection to our platform.</p>
            <p>Information we collect as you use our services:</p>
            <p>In order to make our services more curated, we store the information we collect from you along with the personal information, as retrieved from your user, when you are accessing our platform. The information we collect from you bear a lot many unique identifiers, including browser type settings, operating system, mobile network information including the carrier details and the phone number, device ID, device type settings. We also collect information about the interaction of your browsers, apps, and devices with our services, including IP address, crash reports, performance data, system activity, and the date, time, referrer URL of your request. We collect this information, when you have logged onto, or browse through our platform.</p>
            <p>We collect information about your activity in our services, which we use to do things like recommend you videos you may like. The activity information we collect may include:</p>
            <ul>
                <li>Terms you search for</li>
                <li>Videos you watch</li>
                <li>Views and interactions with content across the platform and various ads</li>
                <li>Purchase activity</li>
                <li>Activity on third party sites and apps which use our services</li>
            </ul>
            
            <h2>WHY WE COLLECT YOUR INFORMATION?</h2>
            <p>We collect and use your information in the following ways:</p>
            <ul>
                <li>Provision of our services: we use your information to provide our services to you.</li>
                <li>Maintain and improve our own services: we use your information to understand how efficiently our services are working for you. By learning about your browser and operating system, tracking outages, troubleshooting issues, which are reported by you, we aim at providing better maintenance and upgrades and where necessary. This is aimed at making your experience on our platform smoother.</li>
                <li>Upgrades: by understanding your pattern in using various features of the Platform, we are able to understand better what can be bettered on our platform, and accordingly provide with upgrades.</li>
                <li>Personalized services, including various content and ads: in order to be able to provide recommendations, personalized content, and customized search results, we use the information collected from you for the purposes of the outlined customization. However, personalized ads are not targeted basis any sensitive categories of personal information, such as race, religion, health, or sexual orientation. Your personally identifiable information is not shared with the advertisers unless and until you ask us to.</li>
                <li>Data Analytics: we use data analytics and other techniques to understand how our services are utilized. By checking the number of visits to our platform, we work around the design of the platform. We also use such data to help advertisers understand the effect of their advertisement campaigns.</li>
                <li>Communication: we use the information we collect from you, to interact with you. For sending necessary notifications, including provision of notifications related to any suspicious activity, we need to reach out to you directly. In order to notify any changes in our products, services, or policies, we would like to let you know in time. Where you reach out to us with any service requests, we also keep a record of such interactions in order to serve you better later.</li>
                <li>Email marketing (if applicable): With your permission, we may send you emails about our platform, new products and other updates.</li>
            </ul>
            <p>Depending on your account settings, your activity on our platform may be associated with your personal information in order to improve Zonet Communications’ services and the ads delivered to you, by us. While we use different technologies to process your information, we employ such techniques to analyze the content for better search results, personalized ads, and customize other features of the platform for you. We also analyze the content, to check for spams, malwares, and any illegal content. We will always ask for your consent, before we proceed with using your information for a purpose that has not been covered by this Privacy Policy.</p>
            
            <h2>CONSENT</h2>
            <p>How do you get my consent?</p>
            <p>When you provide us with personal information, we imply that you consent to our collecting it and using it for that specific reason only.</p>
            <p>If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.</p>
            <p>How do I withdraw my consent?</p>
            <p>If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at <a href="mailto:support@zonet.tv">support@zonet.tv</a> or mailing us at: Zonet Communications, 3rd Floor, Zorun, Rev Zairema Building, Zarkawt, Aizawl, 796001, Mizoram</p>
            
            <h2>DISCLOSURE</h2>
            <p>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</p>
            

            <h2>PAYMENT</h2>
            <p>We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.</p>
            <p>Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.</p>
            <p>PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.</p>
            <p>For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com</p>
            
            <h2>SECURITY</h2>
            <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>

            <h2>THIRD-PARTY SERVICES</h2>
            <p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.</p>
            <p>However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.</p>
            <p>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.</p>
            <p>In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</p>
            <p>Once you leave our store’s website or application or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our platform’s Terms of Service.</p>
            <p>Links</p>
            <p>When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>
            
            <h2>SECURITY</h2>
            <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>

            <h2>COOKIES</h2>
            <p>We use cookies to maintain session of your user. It is not used to personally identify you on other websites.</p>
            

            <h2>AGE OF CONSENT</h2>
            <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
            
            <h2>CHANGES TO THIS PRIVACY POLICY</h2>
            <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the application/website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>
            <p>If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.</p>
            
            <h2>QUESTIONS AND CONTACT INFORMATION</h2>
            <p>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at <a href="mailto:support@zonet.tv">support@zonet.tv</a> or by mail at Zonet Communications, 3rd Floor, Zorun, Rev Zairema Building, Zarkawt, Aizawl, 796001, Mizoram</p>
        </div>
    </div>
</div>