import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PageTitleService } from '../title/page-title.service';

// import { environment } from '../../../environments/environment';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class EventTrackingService {

  detailRoute = '/';
  searchAlpha: any;

  constructor(private pageTitleService: PageTitleService) { }

  // public eventEmitter(
  //   eventAction: string,
  //   eventCategory: string,
  //   eventLabel: string,
  //   eventValue?: number) {

  //   //let label =  eventLabel == '' ? eventLabel : '';
    
  //   gtag('event', eventAction, {
  //     'event_category': eventCategory,
  //     'event_label': eventLabel,
  //     'value': eventValue
  //   });

    
  // }

  public eventEmitter(
    event: string,
    eventCategory: string,
    eventLabel: string,
    eventValue?: number,
    eventUserName?: string,
    eventAction?: string) {

    //let label =  eventLabel == '' ? eventLabel : '';
    
    gtag('event', event, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': eventValue,
      'userName': eventUserName,
      'user_name': eventUserName,
      'action': eventAction
    });

    
  }

  public routeTrack(route: any) {


    let title = this.pageTitleService.getTitle();

    if(title == 'Detail' || title == 'NexGTV'){
      // setTimeout(() => {
      //   this.trackRoute(this.pageTitleService.getTitle(), route);
      // }, 2000);

      this.detailRoute = route;
    }else{
     // this.trackRoute(title, route);
     if(title =="Zonet Empai | Search"){
      this.searchAlpha = route.split("q=");
      if(this.searchAlpha[1].length > 3){
        this.trackRoute(title, route);
      }
    }else{
      this.trackRoute(title, route);
    }
    }
  }
  trackRoute(title, path) {
    gtag('config', environment.googleTrackingCode,
      {
        'page_title': title,
        'page_path': path,
        'page_location': path
      }
    );


    // gtag('event', 'page_view', {
    //   page_title: title,
    //   page_location: evt.urlAfterRedirects,
    //   page_path: evt.urlAfterRedirects,
    //   send_to: 'G-P0QS3F78NT'
    // })
  }

  


}


 // switch(route){
    //   case '' :
    //     title = 'Home';
    //     break;
    //     case '/' :
    //     title = 'Home';
    //     break;
    //   case '/livetv' :
    //     title = 'Live Tv';
    //     break;
    //     case '/movies' :
    //       title = 'Movies';
    //       break;
    //     case '/tvshows' :
    //       title = 'Tv Shows';
    //       break;
    //     case '/videos' :
    //       title = 'Videos';
    //       break;
    //     case '/myaccount':
    //       console.log('title '+ this.pageTitleService.getTitle());
    //     title = 'My Account - '+localStorage.getItem('sessionId');
    //       break;
    //     case '/playlist':
    //       title = 'My Watchlist - '+localStorage.getItem('sessionId');
    //       break;


    //       default : 
    //       title = 'No Page title';
    //       break;
    // }
    // console.log('event title '+ title);