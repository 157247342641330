import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { map } from 'rxjs/operators';
import { CatlougeTypeConstants, tempLiveData } from "src/app/core/constants";
import * as _ from 'lodash';


@Injectable()
export class AssetsService {
  userInfo: any = localStorage.getItem('userInfo');
  sessionId: any;
  catlouge: string;
  viewAllData: any = [];
  homePageAssetsData: any = [];
  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) {

  }

  getHomePageTabData(params) {

    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      this.homePageAssetsData = data;
      return data;
    }));

  }

  getContinueWatchingData(params) {

    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      this.homePageAssetsData = data;
      return data;
    }));

  }


  getHomePageAssets(params) {

    if (this.jwtService.getToken()) {
      if (typeof this.userInfo != 'object') {
        this.userInfo = JSON.parse(this.userInfo);
      }
      // this.userInfo = JSON.parse(this.userInfo)
      this.catlouge = localStorage.getItem('sessionId');
      this.catlouge = CatlougeTypeConstants.catlogue;
      if (this.userInfo) {
        this.sessionId = this.userInfo.result.sessionId;
      }
      return this.apiService.get('homecategoryasset?catlogue=' + this.catlouge).pipe(map(data => {
        this.homePageAssetsData = data;
        return data;
      }));

    }

  }

  get getHomePageAssetsData() {
    return this.homePageAssetsData;
  }


  // deleteContinueWatching(recentWatchedDelete) {


  //   return this.apiService.post('videoend',  recentWatchedDelete).pipe(map(data => {
  //     return data;
  //   }));

  // }

  deleteContinueWatching(recentWatchedDelete) {

    return this.apiService.get(`tabdata?${recentWatchedDelete}`).pipe(map(data => {
      return data;
    }));

  }


  detailEpisode(params) {
    return this.apiService.get(`assetdetailpage?${params}`).pipe(map(data => {
      return data;
    }));

  }


  getRecommended(params) {
    return this.apiService.get(`recommandation?${params}`).pipe(map(data => {
      return data;
    }));

  }

  // getAssetDetail(params) {
  //   return this.apiService.get(`assetdetailpage?${params}`).pipe(map(data => {
  //     return data;
  //   }));
  // }

  getAssetDetail(params) {
    return this.apiService.get(`assetdetail?${params}`).pipe(map(data => {
      return data;
    }));
  }

  getViewAll(params) {

    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      return data;
    }));

  }


  // getViewAll(params, pageType, gener?: any) {

  //   let viewAllPath = ''
  //   if (pageType == 'movie') {

  //     viewAllPath = 'genrewithassets'

  //   } else if (pageType == 'vod') {

  //     viewAllPath = 'livetv'

  //   } else if (pageType == 'tvshow') {

  //     viewAllPath = 'genrewithassets'

  //   } else if (pageType == 'livetv') {

  //     viewAllPath = 'genrewithassets'

  //   } else {
  //     viewAllPath = 'homecategoryasset'
  //   }
  //   let dat: any = [];
  //   return this.apiService.get(`${viewAllPath}?${params}`).pipe(map(data => {

  //     if (gener)
  //       dat[gener] = data;

  //     let index =  this.viewAllData.findIndex(d =>d[gener] != undefined )

  //     if (index > -1) {
  //       dat.splice(index, 0, dat)
  //     } else {
  //       this.viewAllData.push(dat);
  //     }

  //     return data;
  //   }));

  // }

  viewAllSavedData(gener) {
    return this.viewAllData.find(d => d[gener] != undefined)
  }


  getEpgData() {
    // return 'epgData'
    let programDateArr = [];
    let test = tempLiveData.egpData;
    let tempProgramByData = [];
    console.log(test);
    test.result.forEach(element => {
      programDateArr.push(element.progdate)
      // console.log(programDateArr)
    });
    console.log(_.uniq(programDateArr));
    let programDateUniqArr = _.uniq(programDateArr)
    programDateUniqArr.forEach((element, index) => {
      console.log(index, programDateUniqArr.length - 1);
      tempProgramByData.push(test.result.filter(program => element == program.progdate));
      if (index == programDateUniqArr.length - 1) {
        console.log(tempProgramByData)
      }
    });
  }


  getContestDetail(params) {
    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      return data;
    }));
  }

  postContestDetail(credentials){
    this.catlouge = CatlougeTypeConstants.catlogue;
    return this.apiService.post('tabdata?catlogue='+this.catlouge+'&call=votingpoll', credentials).pipe(map(data => {
      return data;
    }
    ));

  }

}

