
<div class="auth tellu" id="login">
    <div class="modal-dialog">
          <div class="modal-content">
      
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Tell Us About You</h4>
              <!-- <button  type="button" class="close" (click)="closeModal()" #closebutton>&times;</button> -->
            </div>
            <div class="modal-body">
      
              <form [formGroup]="tellUsForm" (ngSubmit)="postTellUs()" class="resettwo tellus_view">
               
                <div *ngFor="let tell of tellUsAllFieldsArray; let indexOfelement=index;">
                  <div *ngIf="tell.type=='input'" class="form-group">
                    <div class="row">
                      <div class="col-md-12">
      
                        <div class="scroll_height">
                          <input type="text" class="form-control" [formControlName]="tell.slug" placeholder="{{tell.title}}">
                        </div>
                        </div>
                        <div class="col-md-12">
                        <div *ngIf='isTellUsSubmitted && tellFormControls[tell.slug].errors' class="_require_filed">
                          <span
                            *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                            {{tell.error_message}}
                          </span>
                        </div>
      
                      </div>
                    </div>
                  </div>
                  <div *ngIf="tell.type=='input_calender'" class="form-group">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="tellus_view_label">{{tell.title}}</label>
                      </div>
      
                      <div class="col-md-8">
                        <!-- <input type="text"
                        placeholder="DD/MM/YYYY"
                        class="form-control datepicker" formControlName="{{tell.slug}}"
                        bsDatepicker [maxDate]="maxDate"> -->
                        <input type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control datepicker" formControlName="mycustomDate{{indexOfelement}}"
                    bsDatepicker [maxDate]="maxDate" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true }"> 
                    </div>
  
                    <div class="col-md-12">
                      <div *ngIf="isTellUsSubmitted && tellFormControls['mycustomDate'+indexOfelement].errors" class="_require_filed">
                        <span
                          *ngIf='tellFormControls["mycustomDate"+indexOfelement].hasError("required") && (tellFormControls["mycustomDate"+indexOfelement].dirty || tellFormControls["mycustomDate"+indexOfelement].touched || tellFormControls["mycustomDate"+indexOfelement].errors.required)'>
                          {{tell.error_message}}
                        </span>
  
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="tell.type=='radio'">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="tellus_view_label">{{tell.title}} </label>
                      </div>
                      <div class="col-md-8">
                        <ul>
                          <!--*ngFor="let rad of tell.attributes"-->
                          <li *ngFor="let rad of tell.attributes">
                            <label class="gander_radio">
                              <input type="radio" name="{{tell.slug}}" formControlName="{{tell.slug}}"
                                value="{{rad}}">{{rad}}</label>
                            </li>
                          
                        </ul>
                        <div class="col-md-12">
                          <div *ngIf='isTellUsSubmitted && tellFormControls[tell.slug].errors' class="_require_filed">
                            <span
                              *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                              {{tell.error_message}}
                            </span>
      
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
      
                </div>
      
                <button type="submit" class="btn btn-default register _otp_reg">Done</button>
              </form>
            </div>
          </div>
        </div>
      </div>