import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'image-manipulation',
  templateUrl: './image-manipulation.component.html',
  styleUrls: ['./image-manipulation.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class ImageManipulationComponent implements OnInit {

  BASE_ASSETS_PATH: string = environment.BASE_ASSETS_PATH;// 'https://res.cloudinary.com/digivive/image/upload/';
  APP_CLOUD_NAME: string = environment.APP_CLOUD_NAME;
  EXT: string = environment.SRC_EXT;

  @Input()
  public params: any = {};

  @Input()
  img: string;
  @Input()
  notFound: string;

  assetsImage: string = '';

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    // alert(this.notFound);
    this.assetsImage = this.commonService.compressImgUrl2(this.img,this.params,this.notFound);

    // if (Object.keys(this.params).length == 0 || this.params == undefined) {
    //   this.assetsImage = this.img;
    // } else {

    //   let resizeAlog = '';
    //  let defaultParms = 'f_auto,q_auto,';
    //   if (this.params.width != undefined) {
    //     resizeAlog = resizeAlog+'w_' + this.params.width + ',';
    //   }

    //   if (this.params.height != undefined) {
    //     resizeAlog = resizeAlog+'h_' + this.params.height + ',';
    //   }
    //   // croping modes =>  https://cloudinary.com/documentation/image_transformations#resizing_and_cropping_images
    //   if (this.params.crop != undefined) {
    //     resizeAlog = resizeAlog+'c_' + this.params.crop + ',';
    //   }
    //   // radius doc => https://cloudinary.com/documentation/image_transformations#rounding_corners_and_creating_circular_images
    //   if (this.params.radius != undefined) {
    //     resizeAlog = resizeAlog+'r_' + this.params.radius + ',';
    //   }
    //   // auto, face, faces, eyes, center
    //   if (this.params.gravity != undefined) {
    //     resizeAlog = resizeAlog+'g_' + this.params.gravity + ',';
    //   }

    //   //effect modes => https://cloudinary.com/documentation/image_transformations#applying_image_effects_and_filters
    //   if (this.params.effect != undefined) {
    //     resizeAlog = resizeAlog+'e_' + this.params.effect + ',';
    //   }

    //   resizeAlog = resizeAlog.trim();
    //   if (resizeAlog == '') {
    //     this.assetsImage = this.img;
    //     return;
    //   } else {
    //     resizeAlog = resizeAlog.substr(0, (resizeAlog.length - 1));
    //   }
      
    //   let index = this.img.lastIndexOf("/") + 1;
    //   let filename = this.img.substr(index);
    //   let assetname = filename.substr(0, filename.lastIndexOf("."));

    //   this.assetsImage = this.BASE_ASSETS_PATH + defaultParms+resizeAlog+'/'+this.APP_CLOUD_NAME+'/'+assetname+this.EXT;

    // }


  }
  ngAfterViewInit(): void {
  }

}
