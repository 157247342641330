<!-- Episode list -->

<section class="trend_search mt40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="accinner-head">Showing all result for
          <span>"{{searchQuery | slice:0:20}}"
            <!-- {{searchRes.hits.total.value}} -->

            <!-- {{searchRes.hits.hits.length}} -->

          </span>
        </h4>
      </div>
    </div>
  </div>
  <div class="search_assests">
    <!-- <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" *ngIf="showAllRecordsTab">
                <a class="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">ALL ({{  allRecords }})</a>
              </li>
              <li class="nav-item" *ngIf="showLiveTab">
                <a class="nav-link" #livetvTabActiveClick id="livetv-tab" data-toggle="tab" data-target="#livetv" role="tab" aria-controls="livetv" aria-selected="false">LIVE TV ({{liveTvRecords }})</a>
              </li>
              <li class="nav-item" *ngIf="showMovieTab">
                <a class="nav-link" id="movies-tab" data-toggle="tab" #liveMoviebActiveClick data-target="#movies" role="tab" aria-controls="movies" aria-selected="false">MOVIES ({{liveMoviesRecords}})</a>
              </li>
              <li class="nav-item" *ngIf="showTvTab">
                  <a class="nav-link" id="tv-shows-tab" data-toggle="tab" #livetvshowTabActiveClick data-target="#tv-shows" role="tab" aria-controls="tv-shows" aria-selected="false">Series ({{tvshowRecords}})</a>
                </li>
              <li class="nav-item" *ngIf="showVideoTab">
                <a class="nav-link" id="videos-tab" data-toggle="tab" #liveVideoTabActiveClick data-target="#videos" role="tab" aria-controls="videos" aria-selected="false">VIDEOS ({{videosRecords}})</a>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    <div class="tab-content search-content" id="myTabContent">
      <div *ngIf="showAllRecordsTab" class="tab-pane fade show active" id="all" role="tabpanel"
        aria-labelledby="all-tab">


        <ais-instantsearch [config]="globalConfig">
          <ais-configure [searchParameters]="allsearchParameters"></ais-configure>

          <ais-hits>
            <ng-template let-hits="hits" let-results="results">
              <div class="other_assests">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="view_more">
                        <!-- <input type="hidden" [value]="results.nbHits" #totalAllRecord>
                        <input type="hidden" [value]="jsonString(hits)" #totalAllRecordData> -->
                        <div class="_v_all_parent">
                          <div class="_view_img_list_p default_assests_view" *ngFor="let hit of hits">

                            <div (click)="goto(hit, content)" class="_watching_img1">
                              <div class="p_g_i">
                                <!-- npm -->
                                <!-- <img *ngIf="isCloudFrontType == false && !hit.thumbnail || isCloudFrontType == true && !hit.thumbnail_cloudfront_url" src="assets/img/not-found/landscape.png"
                                  class="grid_item not_found_img" alt="grid_img"> -->

                                <image-manipulation  [img]="isCloudFrontType == false ? hit.thumbnail : is_algolia ? hit.thumbnail_cloudfront_url : hit.image"
                                  [params]="{'width':330,'height':180, 'imgType':'portrait'}" [notFound]="notFoundImg"></image-manipulation>
                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                              <img class="crown"  src="assets/img/crown.png">
                          </a> -->

                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) == 'paid'">
                            <img class="crown"  src="assets/img/crown.png">
                        </a>

                        <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'free'">
                            <img class="crown" src="assets/img/free_tag_76x35.png">
                         </a>

                         <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'no tag'">
                         </a> -->
                                <a class="_cross_icon" *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                  <img class="crown" data-toggle="modal" data-target="#removeWatchListSlider"
                                    src="assets/img/asset_cross_icon.png">
                                </a>
                                <span class="search_live" *ngIf="hit.type == 'live' || hit.type == 'livetv'">Live tv</span>
                                <span class="search_event" *ngIf="hit.type == 'event'">Live Event</span>
                                <span class="search_movie" *ngIf="hit.type == 'movie'">Movies</span>
                                <span class="search_series" *ngIf="hit.type == 'tvshow'">Shows</span>
                                <span class="search_movie search_video" *ngIf="hit.type == 'vod'">Videos</span>
                                <span class="search_movie search_video" *ngIf="hit.type == 'video'">Videos</span>

                                <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                  <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                                    aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}">
                                  </div>
                                </div>
                              </div>
                              <div class="_visible_hover1">
                                <div class="_v_c_l">
                                  <a href="javascript:;">
                                    <div class="_c_img">
                                      <img src="assets/img/hover_Play.png">
                                    </div>
                                    <div class="_c_progress">
                                      <span>watch now</span>
                                    </div>
                                  </a>


                                  <div class="_right_c_t">

                                    <!--                         
                          <span class="rating_s">
                            U/A 16+
                          </span> 
                        -->
                                    <span class="rating_s"
                                      *ngIf="(hit?.viewer_rating) && hit?.viewer_rating?.rating_name">
                                      {{hit?.viewer_rating?.rating_name}}
                                    </span>

                                    <!-- <a class="add_play_llist" (click)="AddToWatchList($event, hit, content)"> 
                                <img [src]="hit.is_watchlist_added?'assets/img/Added_to_Watchlist.png':'assets/img/Add-to_Watchlist.png'">                                                                                               
                                <span class="_hover_watchlist">
                                    {{hit.is_watchlist_added?'Remove from Watchlist':'Add to Watchlist'}}   
                                </span>
                            </a> -->
                                  </div>

                                </div>
                                <div class="_inner_text_l">
                                  <h4>{{hit.name | slice:0:40}}
                                    <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                  </h4>
                                  <p class="type_film">
                                    {{hit.genre_text?hit.genre_text:''}}
                                    {{hit.languages?"|":""}}
                                    {{hit.languages?hit.languages:''}}
                                  </p>
                                  <p>{{hit?.synopsis | slice:0:100}}
                                    <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="results.nbHits > 20" class="_search_pagination">
                          <ais-pagination totalPages="5"></ais-pagination>
                        </div>
                        <div class="_not_find">
                          <h4 class="no-result" *ngIf="hits.length == 0">Sorry, we couldn't find any results matching
                            <span> <b>"{{searchQuery}}" </b></span></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ais-hits>
        </ais-instantsearch>


        <!-- <div class="other_assests">
            <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="view_more">

                  
              <div class="_v_all_parent" *ngIf="searchRes.hits?.total.value > 0 ">
              <div class="_view_img_list_p" *ngFor="let hit of searchRes.hits.hits">
           
                <div (click)="goto(hit._source, content)" class="_watching_img1">                   
                  <div class="p_g_i" >
                      <img *ngIf="!hit._source.thumbnail" src="assets/img/image-not-found.png" class="grid_item" alt="grid_img">

                      <image-manipulation *ngIf="hit._source.thumbnail" [img]="hit._source.thumbnail" [params]="{'width':330,'height':180}"></image-manipulation>
                      <a  class="_premium" *ngIf="strToLower(hit._source.content_availability) != 'free'">
                          <img class="crown"  src="assets/img/crown.png">
                      </a>
                      <a  class="_cross_icon"  *ngIf="strToLower(hit._source.category_name) == 'continue watching'">
                          <img class="crown"  data-toggle="modal"  data-target="#removeWatchListSlider" src="assets/img/asset_cross_icon.png" >  
                      </a>
                      <span class="search_live" *ngIf="hit._source.type == 'livetv'">Live</span>
                      <span class="search_movie" *ngIf="hit._source.type == 'movie'">Movies</span>
                      <span class="search_series" *ngIf="hit._source.type == 'tvshow'">Series</span>
                      <div *ngIf="strToLower(hit._source.category_name) == 'continue watching'" class="progress">
                        <div  class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': hit._source.stop*100/hit._source.duration+'%'}" >                                  
                        </div>
                      </div>
                  </div>
                  <div class="_visible_hover1" >
                      <div class="_v_c_l">
                      <a href="javascript:;">
                        <div class="_c_img">
                          <img src="assets/img/hover_Play.png">
                        </div>
                        <div class="_c_progress">
                          <span>watch now</span>
                        </div>
                      </a>                                
                      <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)"> 
                        <img src="assets/img/Add-to_Watchlist.png">                                
                        <span class="_hover_watchlist">Add to watchlist</span>
                      </a>
                  </div>    
                  <div class="_inner_text_l" >
                      <h4 >{{hit._source.name | slice:0:40}}
                        <span *ngIf="hit._source.name && hit._source.name.length > 40">...</span>
                      </h4>                        
                      <p class="type_film">
                        {{hit._source.genre_text?hit.genre_text:''}}
                        {{hit._source.languages?"|":""}}
                        {{hit._source.languages?hit.languages:''}}
                      </p>
                      <p>{{hit._source?.synopsis | slice:0:100}}
                        <span *ngIf="hit._source.synopsis && hit._source.synopsis.length > 100">...</span>
                      </p>
                  </div>
                </div>                                                                                   
              </div>
              </div>
              </div>
              <div *ngIf="searchRes.hits?.total.value > 20" class="_search_pagination">

                <ngb-pagination 
                class="d-flex justify-content-center"
                  [collectionSize]="searchRes.hits.total.value" 
                  [(page)]="page"
                  [pageSize]="20"
                  [rotate] ="true" 
                  [maxSize]="7" 
                  aria-label="Default pagination"
                  [ellipses]="false" 
                  [boundaryLinks]="true"
                  (pageChange)="getPage($event)"
                ></ngb-pagination>
              </div>
              <div class="_not_find">
              <h4 class="no-result" *ngIf="searchRes?.hits?.hits.length == 0">Sorry, we couldn't find any results matching <span> <b>"{{searchQuery  | slice:0:20}}" </b></span></h4>
            </div>
            </div>
            </div>
          </div>
          </div>
          </div>           -->

        <!-- <span *ngIf="showLiveTab">
       <section  class="_custom_paren_img mini_slider" [ngClass]="{'assets_hover': assets_hover1}" *ngIf="allhits.tvrecords.length > 0"> 
          <div class="_custom_cont">
            <div class="container">
                <div class="row">
                  <div class="col-sm-12">
                  <div class="i_head_c">
                      <h4>Live Tv</h4>
                      <div *ngIf="liveTvRecords*1 > 4" class="_view_all_list_p">
                      <a href="javascript:void(0)" (click)="changeTab('livetv')">View All</a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>

            <div class="_custom_grid_img"  [ngClass]="{'assets_hover': assets_hover1 }">     
              <div class="container">
                <div class="row">
                  <div class="col-sm-12">               
                      <div class="owl-carousel four_slides owl-theme" style="margin-top:20px;"  [ngClass]="{'_less_four':liveTvRecords <= 4 }">
                          <owl-carousel-o [options]="owlOption">
                              <ng-container *ngFor="let hit of allhits.tvrecords">
                              <ng-template carouselSlide>
                                  
                                  <div class="item">
                                      

                                      <div (click)="goto(hit, content)" class="_watching_img1 live_tv" (mouseenter) ="item_hover_enter1()"  (mouseleave) ="item_hover_leave1()">                      
                                                             
                                        <div class="p_g_i" >
                                            <img  src="{{hit.thumbnail?hit.thumbnail:'assets/img/image-not-found.png'}}" class="grid_item" alt="grid_img">
                                            
                                            <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                                <img class="crown"  src="assets/img/crown.png">
                                            </a>
                                            <a  class="_cross_icon"  *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                                <img class="crown"  data-toggle="modal"  data-target="#removeWatchListSlider" src="assets/img/asset_cross_icon.png" >  
                                            </a>
                                            <span class="live" *ngIf="hit.type == 'livetv'">Live</span>
                                            <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                              <div  class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}" >                                  
                                              </div>
                                            </div>
                                        </div>
                                        <div class="_visible_hover1" >
                                            <div class="_v_c_l">
                                            <a href="javascript:;">
                                                <div class="_c_img">
                                                  <img src="assets/img/hover_Play.png">
                                                </div>
                                                <div class="_c_progress">
                                                  <span>watch now</span>
                                                </div>
                                            </a>                                
                                            <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)"> 
                                                <img src="assets/img/hover_add_to_watch_list_icon.png">
                                                <span class="_hover_watchlist">Add to watchlist</span>
                                            </a>
                                        </div>    
                                        <div class="_inner_text_l" >
                                            <h4 >{{hit.name | slice:0:40}}
                                              <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                            </h4>                        
                                            <p>{{hit?.synopsis | slice:0:100}}
                                              <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                            </p>
                                      
                                            <p class="type_film">
                                                
                                            </p>
                                        </div>
                                        </div>                                                                                   
                                    </div>
                                  </div>
                              </ng-template>
                          </ng-container>
                          </owl-carousel-o>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </section>
      </span>

          <section   class="_custom_paren_img mini_slider"  [ngClass]="{'assets_hover': assets_hover2 }"  *ngIf="allhits.movierecord.length > 0"> 
            
            <div class="_custom_cont">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12">
                      <div class="i_head_c">
                          <h4>Movies</h4>
                          <div *ngIf="liveMoviesRecords*1 > 4" class="_view_all_list_p">
                          <a href="javascript:void(0)" (click)="changeTab('movies')">View All</a>
                          </div>
                      </div>
                </div>
              </div>
            </div>
          </div>


            <div class="_custom_grid_img" [ngClass]="{'assets_hover': assets_hover2 }">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12"> 
                    <div class="owl-carousel four_slides owl-theme" style="margin-top:20px;"  [ngClass]="{'_less_four':liveMoviesRecords <= 4 }">
                        <owl-carousel-o [options]="owlOption">
                            <ng-container *ngFor="let hit of allhits.movierecord">
                            <ng-template carouselSlide>
                                
                                <div class="item">
                                   
                                    <div (click)="goto(hit, content)" class="_watching_img1" (mouseenter) ="item_hover_enter2()"  (mouseleave) ="item_hover_leave2()">                      
                                                      
                                      <div class="p_g_i" >
                                          <img  src="{{hit.thumbnail?hit.thumbnail:'assets/img/image-not-found.png'}}" class="grid_item" alt="grid_img">
                                          
                                          <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                              <img class="crown"  src="assets/img/crown.png">
                                          </a>
                                          <a  class="_cross_icon"  *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                              <img class="crown"  data-toggle="modal"  data-target="#removeWatchListSlider" src="assets/img/asset_cross_icon.png" >  
                                          </a>
                                          <span class="live" *ngIf="hit.type == 'livetv'">Live</span>
                                          <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                            <div  class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}" >                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div class="_visible_hover1" >
                                          <div class="_v_c_l">
                                          <a href="javascript:;">
                                             <div class="_c_img">
                                                <img src="assets/img/hover_Play.png">
                                              </div>
                                              <div class="_c_progress">
                                                <span>watch now</span>
                                              </div>
                                          </a>                                
                                          <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)"> 
                                              <img src="assets/img/hover_add_to_watch_list_icon.png">
                                              <span class="_hover_watchlist">Add to watchlist</span>
                                          </a>
                                      </div>    
                                      <div class="_inner_text_l" >
                                          <h4 >{{hit.name | slice:0:40}}
                                            <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                          </h4>                        
                                          <p>{{hit?.synopsis | slice:0:100}}
                                            <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                          </p>
                                    
                                          <p class="type_film">
                                    
                                              
                                          </p>
                                      </div>
                                      </div>                                                                                   
                                  </div>
                                </div>
                            </ng-template>
                        </ng-container>
                        </owl-carousel-o>
                    </div>
                  </div>
                </div>
            </div>

            </div>
        </section>

          <section class="_custom_paren_img mini_slider"  [ngClass]="{'assets_hover': assets_hover3 }"  *ngIf="allhits.tvshow.length > 0"> 
            <div class="_custom_cont">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="i_head_c">
                      <h4>SERIES</h4>
                      <div *ngIf="tvshowRecords*1 > 4" class="_view_all_list_p">
                      <a href="javascript:void(0)" (click)="changeTab('tvshows')">View All</a>
                      </div>
                    </div>            
                  </div>
                </div>
              </div>
            </div>
              
              <div class="_custom_grid_img" [ngClass]="{'assets_hover': assets_hover3 }">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12"> 
                        <div class="owl-carousel four_slides owl-theme" style="margin-top:20px;"  [ngClass]="{'_less_four':tvshowRecords <= 4 }">
                            <owl-carousel-o [options]="owlOption">
                                <ng-container *ngFor="let hit of allhits.tvshow">
                                <ng-template carouselSlide>
                                    
                                    <div class="item">
                                        
                                        <div (click)="goto(hit, content)" class="_watching_img1" (mouseenter) ="item_hover_enter3()"  (mouseleave) ="item_hover_leave3()">                      
                                                              
                                          <div class="p_g_i" >
                                              <img  src="{{hit.thumbnail?hit.thumbnail:'assets/img/image-not-found.png'}}" class="grid_item" alt="grid_img">
                                              
                                              <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                                  <img class="crown"  src="assets/img/crown.png">
                                              </a>
                                              <a  class="_cross_icon"  *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                                  <img class="crown"  data-toggle="modal"  data-target="#removeWatchListSlider" src="assets/img/asset_cross_icon.png" >  
                                              </a>
                                              <span class="live" *ngIf="hit.type == 'livetv'">Live</span>
                                              <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                                <div  class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}" >                                  
                                                </div>
                                              </div>
                                          </div>
                                          <div class="_visible_hover1" >
                                              <div class="_v_c_l">
                                              <a href="javascript:;">
                                                 <div class="_c_img">
                                                    <img src="assets/img/hover_Play.png">
                                                  </div>
                                                  <div class="_c_progress">
                                                    <span>watch now</span>
                                                  </div>
                                              </a>                                
                                              <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)"> 
                                                  <img src="assets/img/hover_add_to_watch_list_icon.png">
                                                  <span class="_hover_watchlist">Add to watchlist</span>
                                              </a>
                                          </div>    
                                          <div class="_inner_text_l" >
                                              <h4 >{{hit.name | slice:0:40}}
                                                <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                              </h4>                        
                                              <p>{{hit?.synopsis  | slice:0:100}}
                                                <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                              </p>
                                        
                                              <p class="type_film">
                                        
                                              </p>
                                          </div>
                                          </div>                                                                                   
                                      </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                            </owl-carousel-o>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
       <span *ngIf="showVideoTab">
        <section   class="_custom_paren_img mini_slider"  [ngClass]="{'assets_hover': assets_hover4 }"  *ngIf="allhits.videorecord.length > 0"> 
            
          <div class="_custom_cont">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="i_head_c">
                        <h4>Videos</h4>
                        <div  *ngIf="videosRecords*1 > 4" class="_view_all_list_p">
                        <a href="javascript:void(0)" (click)="changeTab('videos')">View All</a>
                      </div>
                    </div>
                    </div>
                    </div>
                  </div>
              </div>
            
              <div class="_custom_grid_img" [ngClass]="{'assets_hover': assets_hover4 }">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="owl-carousel four_slides owl-theme" style="margin-top:20px;"  [ngClass]="{'_less_four':videosRecords <= 4 }">
                          <owl-carousel-o [options]="owlOption">
                              <ng-container *ngFor="let hit of allhits.videorecord">
                              <ng-template carouselSlide>
                                  
                                  <div class="item">
                                     
                                      <div (click)="goto(hit, content)" class="_watching_img1" (mouseenter) ="item_hover_enter4()"  (mouseleave) ="item_hover_leave4()">                      
                                                          
                                        <div class="p_g_i" >
                                            <img  src="{{hit.thumbnail?hit.thumbnail:'assets/img/image-not-found.png'}}" class="grid_item" alt="grid_img">
                                            
                                            <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                                <img class="crown"  src="assets/img/crown.png">
                                            </a>
                                            <a  class="_cross_icon"  *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                                <img class="crown"  data-toggle="modal"  data-target="#removeWatchListSlider" src="assets/img/asset_cross_icon.png" >  
                                            </a>
                                            <span class="live" *ngIf="hit.type == 'livetv'">Live</span>
                                            <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                              <div  class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}" >                                  
                                              </div>
                                            </div>
                                        </div>
                                        <div class="_visible_hover1" >
                                            <div class="_v_c_l">
                                            <a href="javascript:;">
                                                <div class="_c_img">
                                                  <img src="assets/img/hover_Play.png">
                                                </div>
                                                <div class="_c_progress">
                                                  <span>watch now</span>
                                                </div>
                                            </a>                                
                                            <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)"> 
                                                <img src="assets/img/hover_add_to_watch_list_icon.png">
                                                <span class="_hover_watchlist">Add to watchlist</span>
                                            </a>
                                        </div>    
                                        <div class="_inner_text_l" >
                                            <h4 >{{hit.name | slice:0:40}}
                                              <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                            </h4>                        
                                            <p>{{hit?.synopsis | slice:0:100}}
                                              <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                            </p>
                                      
                                            <p class="type_film">
                                                
                                            </p>
                                        </div>
                                        </div>                                                                                   
                                    </div>
                                  </div>
                              </ng-template>
                          </ng-container>
                          </owl-carousel-o>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </section>
      </span> -->
        <!-- <div class="_not_find">
        <h4 class="no-result" *ngIf="( liveTvRecords*showLiveTab +  liveMoviesRecords*1 + tvshowRecords*1 + videosRecords*showVideoTab) == 0">Sorry, we couldn't find any results matching <span> <b>{{searchQuery}} </b></span></h4>
      </div> -->
      </div>
      <div class="tab-pane fade" id="livetv" role="tabpanel" aria-labelledby="livetv-tab" *ngIf="showLiveTab">
        <ais-instantsearch [config]="globalConfig">
          <ais-configure [searchParameters]="livetvsearchParameters"></ais-configure>

          <ais-hits>
            <ng-template let-hits="hits" let-results="results">
              <div class="other_assests">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="view_more">
                        <input type="hidden" [value]="results.nbHits" #totalLiveTvRecord>
                        <input type="hidden" [value]="jsonString(hits)" #totalLiveTvRecordData>
                        <div class="_v_all_parent live_tv">
                          <div class="_view_img_list_p" *ngFor="let hit of hits">
                            <div (click)="goto(hit, content)" class="_watching_img1"
                              [ngClass]="{'live_tv': hit.type == 'livetv'}">
                              <div class="p_g_i">
                                <!-- <img  src="{{hit.thumbnail?hit.thumbnail:'assets/img/image-not-found.png'}}" class="grid_item" alt="grid_img"> -->
                                <!-- <img *ngIf="isCloudFrontType == false && !hit.thumbnail || isCloudFrontType == true && !hit.thumbnail_cloudfront_url" src="assets/img/image-not-found.png" class="grid_item"
                                  alt="grid_img"> -->

                                <image-manipulation [img]="isCloudFrontType == false ? hit.thumbnail : hit.thumbnail_cloudfront_url"
                                  [params]="{'width':150,'height':150,'crop':'fill','gravity':'center'}" [notFound]="notFoundImg">
                                </image-manipulation>
                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                    <img class="crown"  src="assets/img/crown.png">
                                </a> -->
                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) == 'paid'">
                                  <img class="crown"  src="assets/img/crown.png">
                              </a>
                
                              <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'free'">
                                  <img class="crown" src="assets/img/free_tag_76x35.png">
                               </a>
                
                               <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'no tag'">
                               </a> -->

                                <a class="_cross_icon" *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                  <img class="crown" data-toggle="modal" data-target="#removeWatchListSlider"
                                    src="assets/img/asset_cross_icon.png">
                                </a>
                                <span class="live" *ngIf="hit.type == 'livetv' || hit.type == 'live'">Live</span>
                                <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                  <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                                    aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}">
                                  </div>
                                </div>

                              </div>
                              <div class="_visible_hover1">
                                <div class="_v_c_l">
                                  <a href="javascript:;">
                                    <div class="_c_img">
                                      <img src="assets/img/hover_Play.png">
                                    </div>
                                    <div class="_c_progress">
                                      <span>watch now</span>
                                    </div>
                                  </a>
                                  <a class="add_play_llist" (click)="AddToWatchList($event,hit,content)">

                                    <img
                                      [src]="item.is_watchlist_added ? 'assets/img/Added_to_Watchlist.png' : 'assets/img/Add-to_Watchlist.png'">
                                    <!-- <img src="assets/img/hover_add_to_watch_list_icon.png"> -->
                                    <span class="_hover_watchlist">
                                      {{item.is_watchlist_added?'Remove from Watchlist':'Add to Watchlist'}}
                                    </span>
                                  </a>
                                </div>
                                <div class="_inner_text_l">
                                  <h4>{{hit.name | slice:0:40}}
                                    <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                  </h4>
                                  <p>{{hit.synopsis | slice:0:45}}
                                    <span *ngIf="hit.synopsis && hit.synopsis.length > 45">...</span>
                                  </p>

                                  <p class="type_film">
                                    {{hit.genre_text?hit.genre_text:''}}
                                    {{hit.languages?"|":""}}
                                    {{hit.languages?hit.languages:''}}


                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="results.nbHits > 20" class="_search_pagination">
                          <ais-pagination totalPages="5"></ais-pagination>
                        </div>
                        <div class="_not_find">
                          <h4 class="no-result" *ngIf="hits.length == 0">Sorry, we couldn't find any results matching
                            <span> <b>"{{searchQuery | slice:0:20 }}" </b></span></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ais-hits>
        </ais-instantsearch>

      </div>
      <div *ngIf="showMovieTab" class="tab-pane fade" id="movies" role="tabpanel" aria-labelledby="movies-tab">
        <ais-instantsearch [config]="globalConfig">
          <ais-configure [searchParameters]="moviesearchParameters"></ais-configure>

          <ais-hits>
            <ng-template let-hits="hits" let-results="results">
              <div class="other_assests">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="view_more">
                        <input type="hidden" [value]="results.nbHits" #totalMoviesRecord>
                        <input type="hidden" [value]="jsonString(hits)" #totalMoviesRecordData>
                        <div class="_v_all_parent">
                          <div class="_view_img_list_p" *ngFor="let hit of hits">

                            <div (click)="goto(hit, content)" class="_watching_img1">
                              <div class="p_g_i">
                                <!-- <img  src="{{hit.thumbnail?hit.thumbnail:'assets/img/image-not-found.png'}}" class="grid_item" alt="grid_img"> -->
                                <!-- <img *ngIf="isCloudFrontType == false && !hit.thumbnail || isCloudFrontType == true && !hit.thumbnail_cloudfront_url" src="assets/img/image-not-found.png" class="grid_item"
                                  alt="grid_img"> -->

                                <image-manipulation [img]="isCloudFrontType == false ? hit.thumbnail : hit.thumbnail_cloudfront_url"
                                  [params]="{'width':330,'height':180 , 'imgType':'portrait'}" [notFound]="notFoundImg"></image-manipulation>
                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                  <img class="crown"  src="assets/img/crown.png">
                              </a> -->

                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) == 'paid'">
                                <img class="crown"  src="assets/img/crown.png">
                            </a>
              
                            <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'free'">
                                <img class="crown" src="assets/img/free_tag_76x35.png">
                             </a>
              
                             <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'no tag'">
                             </a> -->

                                <a class="_cross_icon" *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                  <img class="crown" data-toggle="modal" data-target="#removeWatchListSlider"
                                    src="assets/img/asset_cross_icon.png">
                                </a>
                                <span class="live" *ngIf="hit.type == 'livetv' || hit.type == 'live'">Live</span>
                                <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                  <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                                    aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}">
                                  </div>
                                </div>
                              </div>
                              <div class="_visible_hover1">
                                <div class="_v_c_l">
                                  <a href="javascript:;">
                                    <div class="_c_img">
                                      <img src="assets/img/hover_Play.png">
                                    </div>
                                    <div class="_c_progress">
                                      <span>watch now</span>
                                    </div>
                                  </a>
                                  <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)">
                                    <img src="assets/img/hover_add_to_watch_list_icon.png">
                                    <span class="_hover_watchlist">Add to watchlist</span>
                                  </a>
                                </div>
                                <div class="_inner_text_l">
                                  <h4>{{hit.name | slice:0:40}}
                                    <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                  </h4>
                                  <p class="type_film">
                                    {{hit.genre_text?hit.genre_text:''}}
                                    {{hit.languages?"|":""}}
                                    {{hit.languages?hit.languages:''}}
                                  </p>
                                  <p>{{hit?.synopsis | slice:0:100}}
                                    <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="results.nbHits > 20" class="_search_pagination">
                          <ais-pagination totalPages="5"></ais-pagination>
                        </div>
                        <div class="_not_find">
                          <h4 class="no-result" *ngIf="hits.length == 0">Sorry, we couldn't find any results matching
                            <span> <b>"{{searchQuery | slice:0:20}}" </b></span></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ais-hits>
        </ais-instantsearch>

      </div>
      <div *ngIf="showTvTab" class="tab-pane fade" id="tv-shows" role="tabpanel" aria-labelledby="tv-shows-tab">
        <ais-instantsearch [config]="globalConfig">
          <ais-configure [searchParameters]="tvshowsearchParameters"></ais-configure>

          <ais-hits>
            <ng-template let-hits="hits" let-results="results">
              <div class="other_assests">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="view_more">
                        <input type="hidden" [value]="results.nbHits" #totalTvShowRecord>
                        <input type="hidden" [value]="jsonString(hits)" #totalTvShowRecordData>
                        <div class="_v_all_parent">
                          <div class="_view_img_list_p" *ngFor="let hit of hits">
                            <div (click)="goto(hit, content)" class="_watching_img1">
                              <div class="p_g_i">
                                <!-- <img *ngIf="isCloudFrontType == false && !hit.thumbnail || isCloudFrontType == true && !hit.thumbnail_cloudfront_url" src="assets/img/image-not-found.png" class="grid_item"
                                  alt="grid_img"> -->

                                <image-manipulation [img]="isCloudFrontType == false ? hit.thumbnail : hit.thumbnail_cloudfront_url"
                                  [params]="{'width':330,'height':180 , 'imgType':'portrait'}" [notFound]="notFoundImg"></image-manipulation>

                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                    <img class="crown"  src="assets/img/crown.png">
                                </a> -->

                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) == 'paid'">
                                  <img class="crown"  src="assets/img/crown.png">
                              </a>
                
                              <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'free'">
                                  <img class="crown" src="assets/img/free_tag_76x35.png">
                               </a>
                
                               <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'no tag'">
                               </a> -->

                                <a class="_cross_icon" *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                  <img class="crown" data-toggle="modal" data-target="#removeWatchListSlider"
                                    src="assets/img/asset_cross_icon.png">
                                </a>
                                <span class="live" *ngIf="hit.type == 'livetv' || hit.type == 'live'">Live</span>
                                <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                  <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                                    aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}">
                                  </div>
                                </div>
                              </div>
                              <div class="_visible_hover1">
                                <div class="_v_c_l">
                                  <a href="javascript:;">
                                    <div class="_c_img">
                                      <img src="assets/img/hover_Play.png">
                                    </div>
                                    <div class="_c_progress">
                                      <span>watch now</span>
                                    </div>
                                  </a>
                                  <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)">
                                    <img src="assets/img/hover_add_to_watch_list_icon.png">
                                    <span class="_hover_watchlist">Add to watchlist</span>
                                  </a>
                                </div>
                                <div class="_inner_text_l">
                                  <h4>{{hit.name | slice:0:40}}
                                    <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                  </h4>
                                  <p class="type_film">
                                    {{hit.genre_text?hit.genre_text:''}}
                                    {{hit.languages?"|":""}}
                                    {{hit.languages?hit.languages:''}}
                                  </p>
                                  <p>{{hit?.synopsis | slice:0:100}}
                                    <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="results.nbHits > 20" class="_search_pagination">
                          <ais-pagination totalPages="5"></ais-pagination>
                        </div>
                        <div class="_not_find">
                          <h4 class="no-result" *ngIf="hits.length == 0">Sorry, we couldn't find any results matching
                            <span> <b>"{{searchQuery}}" </b></span></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ais-hits>
        </ais-instantsearch>
      </div>
      <div class="tab-pane fade" id="videos" role="tabpanel" aria-labelledby="videos-tab" *ngIf="showVideoTab">
        <ais-instantsearch [config]="globalConfig">
          <ais-configure [searchParameters]="videosearchParameters"></ais-configure>

          <ais-hits>
            <ng-template let-hits="hits" let-results="results">
              <div class="other_assests">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="view_more">
                        <input type="hidden" [value]="results.nbHits" #totalVideosRecord>
                        <input type="hidden" [value]="jsonString(hits)" #totalVideosRecordData>
                        <div class="_v_all_parent">
                          <div class="_view_img_list_p" *ngFor="let hit of hits">
                            <div (click)="goto(hit, content)" class="_watching_img1">
                              <div class="p_g_i">
                                <!-- <img *ngIf="isCloudFrontType == false && !hit.thumbnail || isCloudFrontType == true && !hit.thumbnail_cloudfront_url" src="assets/img/image-not-found.png" class="grid_item"
                                  alt="grid_img"> -->

                                <image-manipulation [img]="isCloudFrontType == false ? hit.thumbnail : hit.thumbnail_cloudfront_url"
                                  [params]="{'width':330,'height':180 , 'imgType':'portrait'}" [notFound]="notFoundImg"></image-manipulation>

                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) != 'free'">
                                  <img class="crown"  src="assets/img/crown.png">
                              </a> -->

                                <!-- <a  class="_premium" *ngIf="strToLower(hit.content_availability) == 'paid'">
                                <img class="crown"  src="assets/img/crown.png">
                            </a>
              
                            <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'free'">
                                <img class="crown" src="assets/img/free_tag_76x35.png">
                             </a>
              
                             <a class="_free_tag" *ngIf="strToLower(hit.content_availability) == 'no tag'">
                             </a> -->
                                <a class="_cross_icon" *ngIf="strToLower(hit.category_name) == 'continue watching'">
                                  <img class="crown" data-toggle="modal" data-target="#removeWatchListSlider"
                                    src="assets/img/asset_cross_icon.png">
                                </a>
                                <span class="live" *ngIf="hit.type == 'livetv' || hit.type == 'live' ">Live</span>
                                <div *ngIf="strToLower(hit.category_name) == 'continue watching'" class="progress">
                                  <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                                    aria-valuemax="100" [ngStyle]="{'width': hit.stop*100/hit.duration+'%'}">
                                  </div>
                                </div>
                              </div>
                              <div class="_visible_hover1">
                                <div class="_v_c_l">
                                  <a href="javascript:;">
                                    <div class="_c_img">
                                      <img src="assets/img/hover_Play.png">
                                    </div>
                                    <div class="_c_progress">
                                      <span>watch now</span>
                                    </div>
                                  </a>
                                  <a class="add_play_llist" (click)="AddToWatchList($event,hit, content)">
                                    <img src="assets/img/hover_add_to_watch_list_icon.png">
                                    <span class="_hover_watchlist">Add to watchlist</span>
                                  </a>
                                </div>
                                <div class="_inner_text_l">
                                  <h4>{{hit.name | slice:0:40}}
                                    <span *ngIf="hit.name && hit.name.length > 40">...</span>
                                  </h4>
                                  <p class="type_film">

                                    {{hit.genre_text?hit.genre_text:''}}
                                    {{hit.languages?"|":""}}
                                    {{hit.languages?hit.languages:''}}

                                    <!-- {{hit.genre_text?hit.genre_text:''}} -->

                                    <!-- {{(hit.hours||hit.min||hit.sec) && hit.genre_text?'|': ''}}  -->

                                    <!-- {{hit.hours>0?hit.hours+' hrs ':''}}
                                  {{hit.min>0?hit.min+' min ':''}}
                                  {{hit.sec>0?hit.sec+' sec ':''}}
                                  {{hit.languages&&(hit.hours||hit.min||hit.sec)?"|":""}}
                                  {{hit.languages?hit.languages:''}}                  -->

                                  </p>
                                  <p>{{hit?.synopsis | slice:0:100}}
                                    <span *ngIf="hit.synopsis && hit.synopsis.length > 100">...</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="results.nbHits > 20" class="_search_pagination">
                          <ais-pagination totalPages="5"></ais-pagination>
                        </div>
                        <div class="_not_find">
                          <h4 class="no-result" *ngIf="hits.length == 0">Sorry, we couldn't find any results matching
                            <span> <b>"{{searchQuery}}" </b></span></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ais-hits>
        </ais-instantsearch>
      </div>
    </div>
  </div>
</section>

<a data-toggle="modal" data-target="#addToWatchListSearch" #addToWatchListSearchSuccess></a>

<div class="modal auth" id="addToWatchListSearch">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header" style="visibility: hidden;">
        <button type="button" id="closeAddToWatchListFromSearch" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <p id="itemAddSuccessMsg" #itemAddedIntoWatch></p>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <app-login></app-login>
</ng-template>