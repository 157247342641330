<section class="maincontentarea mt20">
    <div class="container" id="con">
      <div class="row">
        <div class="col-md-12">
          <div class="i_head_c">
            <h4 class="accinner-head" style="color: #fff;font-weight: 100;">
              Redeem Activation Code</h4>
          </div>
        </div>
      </div>
      <div *ngIf="isRedeemActive">
        <form class="form-group redeem_code" [formGroup]="addRedeemForm" (ngSubmit)="addRedeemCode()">
          <div class="row">
            <div class="col-md-4">
              <h6>Enter activation code</h6>
            </div>
            <div class="col-md-8">
              <div class="scroll_height">
                <input type="text" class="form-control" formControlName="code" placeholder=""
                  id="code" autocomplete="off">
              </div>
  
  
              <div class="_custom_require_f">
                <div class="error _require_filed promocode_error">
                  <span
                    *ngIf="(getCodeFormControl.code.touched || isCodeFormSubmitted) && getCodeFormControl.code.errors?.required">Please
                    Enter Redeem Code</span>
                  <span>{{some_error}}</span>
                </div>
              </div>
              <button type="submit" class="btn btn-default register">Apply Now</button>
            </div>
          </div>
        </form>
        <a #add_promocodesuccess data-toggle="modal" data-target="#add_promocodesuccess"></a>
        <a #add_promoCodeError data-toggle="modal" data-target="#add_promoCodeError"></a>
      </div>
    </div>

    <div class="success-add" id="success-add" style="display:none">
      <img src="../../../assets/img/success.png">
      <p>Activation code successfully applied</p>
      <p>Pack has been activated for Username {{userUnique}}</p>
      <p *ngIf="clicklink=='andorid' || clicklink=='iphone'; else weblink">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.digivive.zonetempai" (click)="closePopup()" *ngIf="clicklink=='andorid'">Click here </a>
          <a target="_blank" href="https://apps.apple.com/in/app/zonet-empai/id1598295475" (click)="closePopup()" *ngIf="clicklink=='iphone'">Click here</a> to access Zonet Empai</p> 
        <ng-template #weblink>
          <p> <a href="javascript:void(0)" (click)="closePopup()" >Click here</a> to access Zonet Empai </p>
      </ng-template> 
    </div>

    <button type="button" style="color: blueviolet;" [hidden]="true" class="dropdown-item"
      (click)="openLoginModal(content)" #openLoginllModel>Open Login Modal</button>
  </section>
  
  <!--Add promocode Success-->
  <div class="modal  auth" id="add_promocodesuccess">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" #closebutton>&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <div class="success-add">
            <img src="../../../assets/img/success.png">
            <p>Activation code successfully applied.</p>
            <p><strong>Fortnightly Pack</strong> Actived For {{userUnique}}</p>
            <p><a routerLink="/" (click)="closePopup()">Click here</a> to Access Zonet Empai</p> 
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  
  <!--Add promocode Error-->
  <div class="modal  auth" id="add_promoCodeError">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <div class="_custom_require_f">
            <div class="error _require_filed"
              style="margin-bottom: 30px !important;text-align:center !important;">
              <span style="color: #fff;">{{promocode_error}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #content let-modal>
    <app-login></app-login>
  </ng-template>
  
  <ng-template #signupContent let-modal>
    <app-register></app-register>
  </ng-template>