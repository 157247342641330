import { Component, OnInit, ElementRef, ViewChild, Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, UserService } from '../../../core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { EmailService } from '../../../core/services/email.service';
import { PlatformLocation, Location } from '@angular/common';
import { UserTypeConstants, CatlougeTypeConstants, resendOtpTimer } from "src/app/core/constants";

import { CommonService } from "src/app/core/services/common.service";
// import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone'; // add this 1 of 4
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { range } from 'rxjs';

@Component({
  selector: 'app-tell-us',
  templateUrl: './tell-us.component.html',
  styleUrls: ['./tell-us.component.css']
})
export class TellUsComponent implements OnInit {

  tellUsForm: FormGroup;
  errors: { errors: {}; };
  @ViewChild('closebutton') closebutton: ElementRef;

  isTellUsSubmitted: boolean = false;
  tellUsAllFieldsArray: any = [];
  errorCode: any;
  tellUsScreen: boolean = false;
  tellUsSuccessMsg: any;
  date: any;
  dateVal: boolean;
  maxDate: any;
  public d = parseInt(new Date().getFullYear()+"");
  public yearList = range(1950, this.d);
  public timeStmp: any;
  public flag: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private emailService: EmailService,
    private platformLocation: PlatformLocation,
    location: Location,
    private fb: FormBuilder,
    public commonService: CommonService,
    // public playListService: PlayListService,
    private googleEvent: EventTrackingService
  ) { }

  ngOnInit(): void {
    this.maxDate = new Date();
    // if (localStorage.getItem('isAuthenticated')) {
    //   this.router.navigateByUrl('/');
    // }
    this.getModal();
    this.tellUsForm = this.fb.group({});
  }


  get tellFormControls() { return this.tellUsForm.controls; }
  // getPlaylist() {
  //   let tempGetPlay = {
  //     catlogue: CatlougeTypeConstants.catlogue,
  //     plateform: "web",
  //     action: "getplaylist",
  //   }

  //   let urlEncodedPlayListParams = this.userService.convertoJSONToUrlEncoded(tempGetPlay);

  //   this.playListService.getPlaylist(urlEncodedPlayListParams).subscribe(data => {
  //     // this.playList = data;

  //     if (data && data.result) {
  //       localStorage.setItem('playListId', data?.result[0]?.playlistid);
  //       this.userService.loginStatus(true);
  //       this.closebutton.nativeElement.click();
  //       this.closeModal();
  //       if (Object.keys(this.commonService.getAssetsData()).length > 0)
  //         this.commonService.goToDetail(this.commonService.getAssetsData(), '', 1);
  //       else
  //         window.location.reload();

  //     }

  //   });
  // }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
      this.tellUsForm.reset();

    } catch (e) {

    }
  }



getModal(){
  let da = "";
  this.userService.getTellUsFields(da).subscribe(data1 => {
    this.errorCode = data1['error_code'];
    console.log(data1);
    if (this.errorCode == "200") {
      if (data1['result']['is_profile_complete'] == 0) {
        this.tellUsAllFieldsArray = data1['result']['additional_field_data'];
        this.tellUsAllFieldsArray.forEach((item:any, index:any)=>{
          if(item.is_required==1){
             if (item.type == 'input_calender') {
            this.tellUsForm.addControl('mycustomDate'+index, new FormControl('', [Validators.required]))
             }else{
            this.tellUsForm.addControl(item.slug, new FormControl('',[Validators.required]))
             }
          }else{
            if (item.type == 'input_calender') {
              this.tellUsForm.addControl('mycustomDate'+index, new FormControl(''))
               }else{
              this.tellUsForm.addControl(item.slug, new FormControl(''))
               }
          }
        });
      } else {
       // this.getPlaylist();
      }
    }

  }, err => {
    this.errors = err;
  });
}


postTellUs() {
  this.errors = { errors: {} };
  this.isTellUsSubmitted = true;
    console.log(this.tellUsForm);
     if (this.tellUsForm.invalid) {
       return;
     }

     this.tellUsAllFieldsArray.forEach((item: any, index: any) => {
      if (item.type == 'input_calender') {
        let newDate = this.tellUsForm.controls["mycustomDate"+index].value;
        if (newDate) {
          this.timeStmp = newDate.getTime() / 1000;
        } else {
          this.timeStmp = "";
        }
        this.tellUsForm.addControl(item.slug, new FormControl(this.timeStmp ?? ''))
      }
    });

    const credentials1 = this.tellUsForm.value;
    this.tellUsAllFieldsArray.forEach((item1: any, index1: any) => {
      if (item1.type == 'input_calender') {
        delete credentials1["mycustomDate"+index1];
      }
    });


    this.flag =1;
    Object.keys(credentials1).forEach((value: any) => {
      if(credentials1[value]!='' &&  credentials1[value] != undefined && credentials1[value] !=null){
        this.flag =0;
      }
      
     });

     this.tellUsForm.addControl('is_additional_profile_field_skipped', new FormControl(this.flag ?? ''));

     const credentials = this.tellUsForm.value;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
     this.userService.getTellUsFields(urlEncodedBodyData).subscribe(data => {
       console.log(data);
       if (data['error_code'] == "200") {
         this.tellUsSuccessMsg = 'Your Record Submited SuccessFully';
         setTimeout(() => {
           console.log(data);
           window.location.reload();
          //this.closebutton.nativeElement.click();
          // this.getPlaylist();
         }, 2000);
      }

     }, err => {
       this.errors = err;
     });

  }

}